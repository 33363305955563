import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import BlogCards from "./BlogCards";
import Footer from "../Footer/Footer";

function Blog() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <BlogCards />
      <Footer />
    </>
  );
}

export default Blog;
