import React from "react";
// import { WhatsAppWidget } from "react-whatsapp-widget";
// import "react-whatsapp-widget/dist/index.css";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import Logo from "./Logo.png";
import WaBg from "./WAbg.png";

const WhatsAppWidgetComponent = () => {
  const logoUrl = "./Logo.png";
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${WaBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <WhatsAppWidget
          phoneNo="919512900526"
          position="right"
          widgetWidth="300px"
          widgetWidthMobile="260px"
          autoOpen={true}
          autoOpenTimer={6000}
          messageBox={true}
          messageBoxTxt="How I Can Apply For Job"
          iconSize="60"
          iconColor="white"
          iconBgColor="#5ad167"
          headerIcon={Logo}
          headerIconColor="pink"
          headerTxtColor="#FFF"
          headerBgColor="#075E54"
          headerTitle="Quarec Resources"
          headerCaption="Online"
          // bodyBgColor="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
          bodyBgImg="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
          chatPersonName="Quarec"
          chatMessage={
            <>
              Hi there 👋
              <br /> How can I help you?
            </>
          }
          footerBgColor="#f0f0f0"
          placeholder="Type a message.."
          btnBgColor="#128c7e"
          btnTxt="Start Chat"
          btnTxtColor="White"
        />
      </div>
    </>
  );
};

export default WhatsAppWidgetComponent;
