import React, { useState, useRef } from "react";
import Logo from "./IMG/LOGO1.png";
import "./NavBar.css";
import "./Btn.css";
import "bootstrap/dist/css/bootstrap.css";
import { NavLink, useNavigate } from "react-router-dom";

function NavBar() {
  const [activeLink, setActiveLink] = useState("");
  const [activeSubLink, setActiveSubLink] = useState("");
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const scrollRef = useRef(null);

  const handleNavLinkClick = (linkName, sectionId) => {
    setActiveLink(linkName);
    setActiveSubLink(sectionId);
    scrollToSection(sectionId);
  };

  const handleServicesDropdownToggle = () => {
    setIsServicesDropdownOpen((prevState) => !prevState);
  };

  const handleServicesDropdownMouseEnter = () => {
    setIsServicesDropdownOpen(true);
  };

  const handleServicesDropdownMouseLeave = () => {
    setIsServicesDropdownOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navLinks = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/AboutUs" },
    {
      name: "Services",
      subLinks: [
        {
          name: "Permanent Staffing",
          sectionId: "permanent-staffing",
          path: "/Services",
        },
        {
          name: "Temporary Staffing",
          sectionId: "temporary-staffing",
          path: "/Services",
        },
        {
          name: "Payroll Management",
          sectionId: "payroll-management",
          path: "/Services",
        },
        {
          name: "Dedicated IT Resources",
          sectionId: "dedicated-it-resource",
          path: "/Services",
        },
        {
          name: "International Recruitment",
          sectionId: "international-recruitment",
          path: "/Services",
        },
        {
          name: "Executive Search",
          sectionId: "executive-search",
          path: "/Services",
        },
        {
          name: "Bulk Hiring",
          sectionId: "bulk-hiring",
          path: "/Services",
        },
      ],
    },
    { name: "Blogs", path: "/Blog" },
    { name: "Contact Us", path: "/ContactUs" },
  ];

  let navigate = useNavigate();
  const routePath = () => {
    let path = `/ApplyJob`;
    navigate(path);
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-dark fixed-top ">
        <div className="container-fluid">
          <a className="navbar-brand " href="/">
            <img className="Le122" src={Logo} alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              {navLinks.map((link, index) => (
                <li className="nav-item" key={index}>
                  {link.subLinks ? (
                    <div
                      className={`dropdown ${
                        link.name === "Services" && isServicesDropdownOpen
                          ? "show"
                          : ""
                      }`}
                      onMouseEnter={handleServicesDropdownMouseEnter}
                      onMouseLeave={handleServicesDropdownMouseLeave}
                    >
                      <span
                        className="nav-link"
                        onClick={handleServicesDropdownToggle}
                      >
                        {link.name}
                      </span>
                      <ul
                        className={`dropdown-menu ${
                          link.name === "Services" && isServicesDropdownOpen
                            ? "show"
                            : ""
                        }`}
                      >
                        {link.subLinks.map((subLink, index) => (
                          <li key={index}>
                            <NavLink
                              className={`dropdown-item ${
                                subLink.sectionId === activeSubLink
                                  ? "active"
                                  : ""
                              }`}
                              activeClassName="active"
                              to={subLink.path}
                              onClick={() =>
                                handleNavLinkClick(
                                  subLink.name,
                                  subLink.sectionId
                                )
                              }
                            >
                              {subLink.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <NavLink
                      exact={link.exact}
                      className={`nav-link ${
                        link.name === activeLink ? "active" : ""
                      }`}
                      activeClassName="active"
                      to={link.path}
                      onClick={() =>
                        handleNavLinkClick(link.name, link.sectionId)
                      }
                    >
                      {link.name}
                    </NavLink>
                  )}
                </li>
              ))}
              <li className="nav-item">
                <a className="btn-11" onClick={routePath}>
                  Apply Job
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
