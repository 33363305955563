import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./NewBlog.css";
import NavBar from "../../Navigation/NavBar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react";
import arrowLeft from "@iconify/icons-mdi/arrow-left";
import Blog1 from "../IMG/23-08-2023/Proven1.svg";
import Blog2 from "../IMG/23-08-2023/Proven2.svg";
import Blog3 from "../IMG/23-08-2023/Proven3.svg";
import Blog4 from "../IMG/23-08-2023/Proven4.svg";
import Blog5 from "../IMG/23-08-2023/Proven5.svg";
import Blog6 from "../IMG/23-08-2023/Proven6.svg";

function Proven() {
  let navigate = useNavigate();
  const GoBack = () => {
    let path = `/Blog`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="subtrnthblog">
        <h1 className="texico">
          <Icon onClick={GoBack} className="iconicals" icon={arrowLeft} />
          10 Proven Ways to Improve Your Hiring Process
        </h1>
        <p className="DateF">24/08/2023 10:30 PM</p>
        <div className="responssetpup">
          <ul>
            {/* <p className="uliu">
      Crafting a compelling pitch deck is a critical component in an
      entrepreneur's quest for funding.
    </p> */}
            <p className="uiliuo">
              The Hiring Process refers to the steps companies take to find and
              hire qualified candidates for an open position. While hiring
              processes may differ depending on a company's size, goals, and
              hiring needs, most follow similar processes to find the best
              candidates for their open job.
            </p>
            <p className="uiliuo">
              Hiring process typically includes seven phases: Locating,
              Attracting, Screening, Interviewing, Narrowing, Selecting and
              Hiring.
            </p>
            <p className="uiliuo">
              <b>
                Some steps which one can take to improve the hiring process:
              </b>
            </p>
          </ul>
          <ol>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog1} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">Define Your Goals</li>
                <p className="uiliuo1">
                  Before you begin the hiring process, take time to decide what
                  you expect the candidate search to look like and determine
                  what your goals are for the hiring process. Defining your
                  goals clearly can help you keep the search focused and ensure
                  you're meeting the company's needs. Create a list of hiring
                  objectives and ask for other's input if you're working with a
                  team to find a suitable candidate. Determine who you want to
                  be involved in the hiring process, think about what you want
                  to prioritize and establish expectations for how longyou want
                  hiring process to last.
                </p>

                <li className="uliu">Identify your ideal candidate</li>
                <p className="uiliuo1">
                  Before you begin your job search, imagine the person you'd
                  like to fill the role. Think about the education level you'd
                  like them to have, their skills, experience and interests. By
                  identifying your ideal candidate, you can decide where best to
                  advertise your job posting. If there are things that are
                  non-negotiable to you or another member of your committee,
                  identify them early on so everyone understands expectations as
                  they go into the process.
                </p>
                <li className="uliu">Find your target audience</li>
                <p className="uiliuo1">
                  With your goals and your idea candidate in mind, decide where
                  you want to advertise your job posting.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <p className="uiliuo1">
                  If you're interested in attracting recent graduates, consider
                  advertising your opening at a local college. Job sites and
                  social media sites can also be great places to connect with
                  your target audience and ensure the right people are applying
                  for your role.
                </p>
                <li className="uliu">Write a clear job description</li>
                <p className="uiliuo1">
                  When posting your open position, write a job description that
                  accurately describes the role and your expectations. The
                  clearer you are in your initial communications, the more
                  likely you are to attract candidates that have the qualities
                  you desire. In your job description, be sure to include
                  elements such as : Time commitment, Responsibilities,
                  Qualifications, Salary expectations, Benefits information,
                  Values, and Perks.
                </p>
                <li className="uliu">
                  Establish a process for reviewing applications
                </li>
                <p className="uiliuo1">
                  After posting the job, decide how long you want to accept
                  applications before closing the post. If you don't notice a
                  lot of qualified candidates applying, consider extending your
                  time frame for candidates to submit their information and
                  review your job posting. You might decide to redesign your
                  qualification requirements or compensation offerings to create
                  opportunities that appeal to candidates and meet their needs.
                  Set yourself a schedule for reviewing applications and create
                  a checklist of what you're looking for in a candidate, such as
                  the level of education or years of experience you desire.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog2} alt="" />
              </div>
            </div>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog3} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">Keep interviews consistent</li>
                <p className="uiliuo1">
                  A report from CareerBuilder found that two in five HR managers
                  who don’t capture onboarding information electronically spend
                  three hours or more onboarding each new employee. This time is
                  spent manually collecting and processing onboarding
                  information that could be automated.
                </p>
                <li className="uliu">
                  Establish a process for reviewing applications
                </li>
                <p className="uiliuo1">
                  After posting the job, decide how long you want to accept
                  applications before closing the post. If you don't notice a
                  lot of qualified candidates applying, consider extending your
                  time frame for candidates to submit their information and
                  review your job posting. You might decide to redesign your
                  qualification requirements or compensation offerings to create
                  opportunities that appeal to candidates and meet their needs.
                  Set yourself a schedule for reviewing applications and create
                  a checklist of what you're looking for in a candidate, such as
                  the level of education or years of experience you desire.
                </p>
                <li className="uliu">Keep interviews consistent</li>
                <p className="uiliuo1">
                  Before you invite qualified candidates to interview, establish
                  your methods for conducting the interviews. Consider the types
                  of candidates you want to invite to interview and develop a
                  detailed list of questions to ask.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <p className="uiliuo1">
                  Creating guidelines for the topics you want to focus on during
                  the interview and the qualities you prefer in a candidate can
                  help you remain consistent when interviewing a variety of
                  different candidates. This can also make it easier to compare
                  candidates when you're in the process of determining who's
                  most qualified for the position.
                </p>
                <li className="uliu">Employ selection testing</li>
                <p className="uiliuo1">
                  Selection testing is the process of screening candidates for a
                  position using a standardized test. The selection test you
                  develop may vary, depending on the position you want to fill.
                  For example, if the position is for a programmer role, the
                  test might include questions about programming tools and
                  methods. Make sure to develop questions that assess each
                  candidate accurately. For candidates with more experience, the
                  test you develop might include more technical questions and
                  may take longer to complete.
                </p>
                <li className="uliu">Automate the hiring process</li>
                <p className="uiliuo1">
                  Consider using a tool like an applicant tracking system (ATS)
                  to automate the hiring process by automatically screening
                  candidates for you. An ATS can track job applications and
                  prioritize the ones that contain keywords matching your job
                  posting. This can help you save time and talent acquisition
                  costs allowing you to put more effort into assessing and
                  interviewing candidates who already meet your predetermined
                  criteria.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog4} alt="" />
              </div>
            </div>

            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog5} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">
                  Personalize the hiring process and conduct thorough background
                  checks
                </li>
                <p className="uiliuo1">
                  Consider personalizing the hiring process by making an effort
                  to get to know candidates. For example, you could create a
                  customized email to send to each candidate who applies to the
                  position that includes their name and that provides them with
                  updates. You might also contact candidates directly after
                  interviews to help them feel more at ease. Personalizing the
                  hiring process can help you develop more referrals and
                  increase your qualified candidate base.
                </p>
                <p className="uiliuo1">
                  Also conduct a thorough background during all stages of the
                  hiring process, from the first interview to the final
                  assessment. This often includes reviewing a candidate's
                  employment history, educational background and criminal
                  history. It may also benefit you to review your candidate's
                  social media profiles to find out more about who they are as
                  people.
                </p>
                <li className="uliu">Continually examine your processes</li>
                <p className="uiliuo1">
                  If you find you're not attracting the calibre of candidates
                  you'd like to see, evaluate each aspect of your hiring process
                  to find parts of it to optimize. Consider asking current
                  employees how they felt about their own hiring process. Use
                  their feedback to streamline the hiring process so you can
                  attract more qualified candidates and improve your recruitment
                  methods. By continuously reviewing each phase of the process,
                  you can ensure that each candidate has a positive experience.
                </p>
              </div>
            </div>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Proven;
