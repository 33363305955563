import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import Footer from "../Footer/Footer";
import "./Agreements.css";

function TermsConditions() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="MainPolicy">
        <h1 className="Ptecs">Terms & Conditions</h1>
        <div>
          <h2 className="Hedingsa">Introduction</h2>
          <p className="SetSubteag">
            Welcome to Quarec Resources ("us," "we," or "our"). These Terms and
            Conditions govern your use of our website [www.quarec.com]. By
            accessing and using our website, you agree to be bound by these
            Terms and Conditions. If you disagree with any part of these terms,
            please refrain from using our website.
          </p>
          <h2 className="Hedingsa">Intellectual Property Rights</h2>
          <p className="SetSubteag">
            All content on this website, including text, graphics, logos,
            images, videos, audio, software, and other materials, are the
            property of Quarec Resources or its licensors and are protected by
            applicable copyright, trademark, and other intellectual property
            laws.
          </p>
          <h2 className="Hedingsa">Use of Website</h2>
          <ul className="SEtpol">
            <li className="prolicytext">
              You may use our website for lawful purposes only. You must not:
            </li>
            <li className="prolicytext">
              Violate any applicable laws or regulations.
            </li>
            <li className="prolicytext">
              Engage in unauthorized access to our systems or user accounts.
            </li>
            <li className="prolicytext">
              Attempt to interfere with the security or functionality of our
              website.
            </li>
            <li className="prolicytext">
              Transmit any harmful, offensive, or illegal content.
            </li>
            <li className="prolicytext">
              Use our website for spamming or unauthorized advertising.
            </li>
          </ul>
          <h2 className="Hedingsa">Links to Third-Party Websites</h2>
          <p className="SetSubteag">
            Our website may contain links to external websites or services not
            operated by us. Please note that we have no control over the content
            or practices of these third-party websites. The inclusion of any
            links does not imply our endorsement or responsibility for the
            linked website. Please review the terms and conditions and privacy
            policies of these websites before using them.
          </p>
          <h2 className="Hedingsa">Changes to Terms and Conditions</h2>
          <p className="SetSubteag">
            We reserve the right to update, modify, or replace these Terms and
            Conditions at any time without prior notice. Your continued use of
            our website after the changes become effective signifies your
            acceptance of the revised terms.
          </p>
          <h2 className="Hedingsa">Governing Law</h2>
          <p className="SetSubteag">
            These Terms and Conditions are governed by and construed in
            accordance with the laws of India, and any disputes arising under
            these terms shall be subject to the exclusive jurisdiction of the
            courts of Ahmedabad
          </p>
          <h2 className="Hedingsa">Contact Us</h2>
          <p className="SetSubteag">
            If you have any questions, concerns, or requests regarding these
            Terms and Conditions, please contact us at career@quarec.com.
          </p>
          <p className="SetSubteag">
            By using our website, you consent to the terms and conditions
            outlined in this document.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsConditions;
