import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./NewBlog.css";
import NavBar from "../../Navigation/NavBar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react";
import arrowLeft from "@iconify/icons-mdi/arrow-left";
import Blog1 from "../IMG/GTA2.png";
import Blog2 from "../IMG/GTA1.png";
import Blog3 from "../IMG/GTA3.png";
import Blog4 from "../IMG/GTA4.png";

function Changed() {
  let navigate = useNavigate();
  const GoBack = () => {
    let path = `/Blog`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="subtrnthblog">
        <h1 className="texico">
          <Icon onClick={GoBack} className="iconicals" icon={arrowLeft} />
          The way we work has changed – now it's time to change the way we hire
        </h1>
        <p className="DateF">By Tom Cornell</p>
        <p className="DateF">14/09/2023 11:00 AM</p>
        <div className="responssetpup">
          <ul>
            {/* <p className="uliu">
          Crafting a compelling pitch deck is a critical component in an
          entrepreneur's quest for funding.
        </p> */}
            <p className="uiliuo">
              Too much talent is slipping through the net, says Tom Cornell,
              because recruitment techniques are not fit for 2023.
            </p>
            <p className="uiliuo">
              The past few years have been ones of change: in the way we work
              and in the way we educate and assess, and yet this transformation
              has had a limited impact on the way we hire. While the pandemic
              brought immense changes to the workplace and its culture, it had
              an even greater impact on the education system.
            </p>
            <p className="uiliuo">
              This is leading many to question how employers will decide between
              the 2021 and 2023 cohorts to ensure equity among the two. This
              argument underscores a greater issue we have with employability
              and equity: can businesses still rely on academic achievement to
              fairly represent the capabilities of an applicant?
            </p>
          </ul>
          <ul>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog1} alt="" />
              </div>
              <div className="supset2">
                {/* <li className="uliu">Define Clear Job Requirements:</li> */}
                <li className="uliu">Beyond academic markers</li>
                <p className="uiliuo1">
                  For years employers have been using the traditional CV and
                  academic achievements as the primary way to separate
                  candidates during the hiring process. While degrees and
                  academic qualifications bring several potential benefits,
                  often many of the attributes and skills associated with them
                  are just assumed. Aside from specialist roles with
                  non-negotiable skills and training such as doctors, teachers
                  or scientists, for many professional jobs, an employer cannot
                  assume that certain academic qualifications directly translate
                  to the qualities of the candidate.
                </p>
                <p className="uiliuo1">
                  The issue surrounding assumed skills and capabilities also
                  relates back to the traditional CV, where there is a huge
                  amount of subjectivity when it comes to what is (or isn’t)
                  presented, how it is presented and how it is then evaluated.
                  The absence of indicators pertaining to specific skills or
                  proficiencies in an application or CV poses the question: does
                  the candidate genuinely lack them, or have they opted not to
                  highlight them?
                </p>
                <p className="uiliuo1">
                  This, therefore, raises the question as to whether existing
                  hiring practices rely too heavily on assumed skills, often
                  leading to inappropriate hires and many suitable candidates
                  being omitted from the process early on. And if so, how can we
                  better assess talent and make more meaningful hiring
                  decisions?
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <li className="uliu">Towards a skills-based approach</li>
                <p className="uiliuo1">
                  Rethinking how we hire requires an overhaul in how we measure
                  candidates' skills and attributes. Instead of assuming they
                  can only be achieved through various qualifications, we should
                  focus on testing for those skills from the offset. Adopting a
                  skills-based approach to hiring replaces cursory CV screens
                  with carefully crafted application forms (with defined scoring
                  keys), biodata questionnaires and assessments.
                </p>
                <p className="uiliuo1">
                  This process isn’t about discrediting the value and
                  opportunity that these qualifications bring, but rather
                  assessing skills that have been developed over time,
                  irrespective of whether these were nurtured through
                  apprenticeships, early job experiences or further education.
                </p>
                <p className="uiliuo1">
                  Adopting a skills-based approach to hiring priorities a strong
                  degree of consistency in what candidates present for
                  assessment, as well as how it is evaluated. Doing so removes
                  subjectivity and bias from the hiring process and instead
                  applies a uniform approach to locating talent. When thinking
                  about this in the context of students who didn’t achieve their
                  desired university grades or are questioning its relevance,
                  knowing that their employability will be assessed on the
                  skills and potential they possess over a single-day
                  performance can boost their job outlook.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog2} alt="" />
              </div>
            </div>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog3} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">Rethinking how we locate talent</li>
                <p className="uiliuo1">
                  The overreliance on outdated hiring practices also translates
                  into a wider issue across all industries: the talent shortage.
                </p>
                <p className="uiliuo1">
                  Since the pandemic, the UK, like many nations, has struggled
                  to find suitable talent across various sectors. Given that
                  unemployment levels are still above pre-2020 levels, it is
                  puzzling that so many businesses are struggling to secure
                  talent. Now is the time for employers to reconsider their
                  recruitment processes and move away from methods that no
                  longer serve their business growth. Instead, they must
                  acknowledge the numerous benefits that skills-based hiring
                  offers businesses when locating talent.
                </p>
                <p className="uiliuo1">
                  This would cast a wider net and extend to those who may have
                  been overlooked in previous HR systems, focusing better on the
                  skills needed to match the job description when searching for
                  the best fit. This is particularly true for younger candidates
                  who will not have had the same opportunity for on-hand
                  experience often desired by employers and so struggle to
                  secure their initial job to improve their employability.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                {/* <li className="uliu">Towards a skills-based approach</li> */}
                <p className="uiliuo1">
                  Especially those who studied during the pandemic now entering
                  the workforce, educational disruption, coupled with limited
                  opportunities for work experience, makes it harder for them to
                  stand out in the traditional CV hiring process. Businesses
                  that are serious about rethinking how they locate and secure
                  talent must seriously consider, on a deeper level, what each
                  individual can bring to the role.
                </p>
                <p className="uiliuo1">
                  To truly move away from outdated hiring practices, employers
                  must shift their search towards aptitude and potential. Using
                  job-matching technology and skills-based hiring algorithms,
                  businesses are more likely to find a greater variety of
                  suitable applicants and realise top talent they would have
                  never secured with traditional recruitment processes.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog2} alt="" />
              </div>
            </div>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Changed;
