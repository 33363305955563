import React, { useState, useEffect } from "react";
import "./App.css";
//Preloader
import Preloader from "./Components/Preloader/Preloader";
import Home from "./Components/Home/Home";
import Apply from "./Components/ApplyJob/Apply";
import AboutUs from "./Components/AboutUs/About";
import ContactUs from "./Components/ContactUs/ContactUs";
// Services Start
import PermanentStaffing from "./Components/Services/PermanentStaffing";
import TemporaryStaff from "./Components/Services/TemporaryStaff";
import PayRoll from "./Components/Services/PayRoll";
import International from "./Components/Services/International";
import DedicatedIt from "./Components/Services/DedicatedIt";
import Executive from "./Components/Services/Executive";
import BulkHiring from "./Components/Services/BulkHiring";
import Services from "./Components/Services/MainServices";
// Services End
import Blog from "./Components/Blog/Blog";
import FullBlog from "./Components/Blog/FullBlog";
//Full Blogs
import QuationOne from "./Components/Blog/Full-Blogs/QuationOne";
import QuationTwo from "./Components/Blog/Full-Blogs/QuationTwo";
import Quationthere from "./Components/Blog/Full-Blogs/QuationThere";
import QuationFour from "./Components/Blog/Full-Blogs/QuationFour";
import QuationFive from "./Components/Blog/Full-Blogs/QuationFive";
import QuationSix from "./Components/Blog/Full-Blogs/QuationSix";
import QuationSeven from "./Components/Blog/Full-Blogs/QuationSeven";
import Quation8 from "./Components/Blog/Full-Blogs/Quation8";
import Quation9 from "./Components/Blog/Full-Blogs/Quation9";
import Quation10 from "./Components/Blog/Full-Blogs/Surprising25";
import Proven from "./Components/Blog/Full-Blogs/Proven";
import InternalMomentum from "./Components/Blog/Full-Blogs/InternalMomentum";
import Changed from "./Components/Blog/Full-Blogs/Changed";
import RecruitmentMarketing from "./Components/Blog/Full-Blogs/RecruitmentMarketing";
import Firstday from "./Components/Blog/Full-Blogs/Firstday"

import { Route, Routes, BrowserRouter } from "react-router-dom";
// Agreements
import Privacypolicy from "./Components/Agreements/Privacypolicy";
import TermsConditions from "./Components/Agreements/TermsConditions";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2400);
    // }, 1600);
  }, []);
  return (
    <div className="App">
      {loading ? ( // Conditionally render the preloader
        <Preloader />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ApplyJob" element={<Apply />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            {/* Services */}
            <Route path="/Services" element={<Services />} />
            <Route path="/PermanentStaffing" element={<PermanentStaffing />} />
            <Route path="/TemporaryStaffing" element={<TemporaryStaff />} />
            <Route path="/PayRoll" element={<PayRoll />} />
            <Route path="/DedicatedIt" element={<DedicatedIt />} />
            <Route path="/International" element={<International />} />
            <Route path="/Executive" element={<Executive />} />
            <Route path="/BulkHiring" element={<BulkHiring />} />
            {/* Services */}
            <Route path="/Blog" element={<Blog />} />
            {/* Full Blogs */}
            <Route path="/QuestionOne" element={<QuationOne />} />
            <Route path="/QuationTwo" element={<QuationTwo />} />
            <Route path="/Quationthere" element={<Quationthere />} />
            <Route path="/QuationFour" element={<QuationFour />} />
            <Route path="/QuationFive" element={<QuationFive />} />
            <Route path="/QuationSix" element={<QuationSix />} />
            <Route path="/QuationSeven" element={<QuationSeven />} />
            <Route path="/FullBlog" element={<FullBlog />} />
            <Route path="/Generative-AI" element={<Quation8 />} />
            <Route path="/Automation" element={<Quation9 />} />
            <Route path="/Surprising25" element={<Quation10 />} />
            <Route path="/Proven" element={<Proven />} />
            <Route path="/Internal-Momentum" element={<InternalMomentum />} />
            <Route path="/Changed" element={<Changed />} />
            <Route path="/Firstday" element={<Firstday/>} />
            <Route
              path="/RecruitmentMarketing"
              element={<RecruitmentMarketing />}
            />
            {/* Agreements */}
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route path="/TermsConditions" element={<TermsConditions />} />
            {/* Agreements */}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
