import React from "react";
import "./Services.css";
// import { Icon } from "@iconify/react";
// import hospitalBuilding from "@iconify/icons-mdi/hospital-building";
import Permanent from "./IMG/Permanent.svg";
import Temporary from "./IMG/Temporary.svg";
import Payroll from "./IMG/Payroll.svg";
import International from "./IMG/International.svg";
import Dedicated from "./IMG/Permanent.svg";
import executive from "./IMG/executive.svg";
import Bulk from "./IMG/Bulk.svg";

function Services() {
  return (
    <>
      <div className="VaolZ081">
        <p className="Vajfs071">Our Services</p>
      </div>
      <div className="VFgf071">
        <div className="Bajfsd06">
          <div className="BhafZZa1">
            <div className="ServImg1">
              {/* <Icon className="iconifybuilding" icon={hospitalBuilding} /> */}
              <img className="iconifybuilding" src={Permanent} alt="" />
              <h2 className="Gkjf12">Permanent Staffing </h2>
              <p className="KJDF065">
                Discover long-term talent. Our permanent staffing connects you
                with exceptional professionals for success. Experience the
                impact of a dedicated workforce that propels your business.
              </p>
            </div>
          </div>
          <div className="BhafZZa1">
            <div className="ServImg2">
              <img className="iconifybuilding" src={Temporary} alt="" />
              {/* <Icon className="iconifybuilding" icon={hospitalBuilding} /> */}
              <h2 className="Gkjf12">Temporary Staffing</h2>
              <p className="KJDF065">
                Our temporary staffing services provide flexible and agile
                skilled professionals on-demand, adapting swiftly to meet
                dynamic business needs and seize opportunities. Scale without
                sacrificing productivity.
              </p>
            </div>
          </div>
          <div className="BhafZZa1">
            <div className="ServImg3">
              <img className="iconifybuilding" src={Payroll} alt="" />
              {/* <Icon className="iconifybuilding" icon={hospitalBuilding} /> */}
              <h2 className="Gkjf12">Payroll Management</h2>
              <p className="KJDF065">
                Simplify payroll management for success. Our efficient solutions
                handle calculations, payments, and compliance effortlessly,
                freeing you to focus on your core business.
              </p>
            </div>
          </div>
          <div className="MopOwqQZx">
            <div className="ServImg4">
              <img className="iconifybuilding" src={International} alt="" />
              {/* <Icon className="iconifybuilding" icon={hospitalBuilding} /> */}
              <h2 className="Gkjf12">International Recruitment</h2>
              <p className="KJDF065">
                Broaden talent horizons with international recruitment. Access
                exceptional professionals worldwide, as we navigate complexities
                seamlessly to bring you the best global talent.
              </p>
            </div>
          </div>
        </div>
        <div className="Bajfsd06">
          <div className="BhafZZa1">
            <div className="ServImg5">
              <img className="iconifybuilding" src={Dedicated} alt="" />
              {/* <Icon className="iconifybuilding" icon={hospitalBuilding} /> */}
              <h2 className="Gkjf12">Dedicated IT Resources</h2>
              <p className="KJDF065">
                Dedicated IT resources provide expertise, support, security,
                scalability, and cost-effectiveness for organizations'
                technology needs.
              </p>
            </div>
          </div>
          <div className="BhafZZa1">
            <div className="ServImg6">
              <img className="iconifybuilding" src={executive} alt="" />
              {/* <Icon className="iconifybuilding" icon={hospitalBuilding} /> */}
              <h2 className="Gkjf12">Executive Search</h2>
              <p className="KJDF065">
                Find visionary leaders through executive search, shaping your
                organization's future for unparalleled success. Discover
                top-tier talent propelling your business to new heights.
              </p>
            </div>
          </div>
          <div className="BhafZZa1">
            <div className="ServImg7">
              <img className="iconifybuilding" src={Bulk} alt="" />
              {/* <Icon className="iconifybuilding" icon={hospitalBuilding} /> */}
              <h2 className="Gkjf12">Bulk Hiring</h2>
              <p className="KJDF065">
                Scale confidently with bulk hiring. Our efficient solutions
                enable rapid workforce expansion. Streamlined processes and a
                focus on quality ensure timely acquisition of skilled candidates
                at scale.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
