import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import Footer from "../Footer/Footer";
import "./Agreements.css";

function Privacypolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="MainPolicy">
        <h1 className="Ptecs">Privacy Policy</h1>
        <div>
          <h2 className="Hedingsa">Introduction</h2>
          <p className="SetSubteag">
            Welcome to www.quarec.com (Quarec Resources Pvt Ltd). We are
            committed to protecting your privacy and ensuring the security of
            your personal information. This Privacy Policy outlines how we
            collect, use, disclose, and protect the data you provide while using
            our website [www.quarec.com].
          </p>
          <h2 className="Hedingsa">Information We Collect</h2>
          <p className="SetSubteag">
            When you visit and interact with our website, we may collect the
            following types of information:
          </p>
          <div>
            <p className="SetSubteag2">
              <b className="Enterbold">A. Personal information: </b>
              Your name, email address, phone number, postal address, or other
              information you voluntarily provide through forms, surveys, or
              communication channels.
            </p>
            <p className="SetSubteag2">
              <b className="Enterbold">B. Registration information:</b> If you
              create an account on our website, we may collect your username,
              password, and other account-related data.
            </p>
            <p className="SetSubteag2">
              <b className="Enterbold">C. Non-personal information:</b> Device
              info, IP address, browser type, OS, referring URLs, and more
              technical data gathered via cookies or similar tech.
            </p>
            <p className="SetSubteag2">
              <b className="Enterbold">D. Usage data:</b> We may collect
              information about your interactions with our website, such as the
              pages visited, time spent on pages, links clicked, and other
              analytical data.
            </p>
          </div>
        </div>
        <h2 className="Hedingsa">Use of Information</h2>
        <p className="SetSubteag">
          We may use the collected information for the following purposes:
        </p>
        <p className="SetSubteag2">
          <b className="Enterbold">A. </b>To personalize your experience on our
          website and deliver relevant content.
        </p>
        <p className="SetSubteag2">
          <b className="Enterbold">B. </b>To process and respond to your
          inquiries, requests, or comments.
        </p>
        <p className="SetSubteag2">
          <b className="Enterbold">C. </b>To provide you with information,
          updates, or marketing communications (with your consent, where
          required by law).
        </p>
        <p className="SetSubteag2">
          <b className="Enterbold">D. </b>To improve our website's
          functionality, performance, and user experience.
        </p>
        <p className="SetSubteag2">
          <b className="Enterbold">E. </b>To conduct research and analysis to
          enhance our services.
        </p>
        <p className="SetSubteag2">
          <b className="Enterbold">F. </b>To comply with legal obligations or
          resolve disputes.
        </p>
        <h2 className="Hedingsa">Cookies and Tracking Technologies</h2>
        <p className="SetSubteag">
          Our website may use cookies, web beacons, and similar tracking
          technologies to enhance your browsing experience. Cookies are small
          data files stored on your device that help us improve our services and
          understand how you use our website. You can modify your browser
          settings to manage cookies preferences or opt-out of certain tracking
          technologies. Please refer to our Cookie Policy for detailed
          information.
        </p>
        <h2 className="Hedingsa">Third-Party Disclosure</h2>
        <p className="SetSubteag">
          We do not sell, trade, or transfer your personal information to third
          parties without your explicit consent, except as outlined in this
          Privacy Policy. We may share your data with trusted service providers,
          business partners, or legal authorities to perform functions on our
          behalf or comply with legal requirements.
        </p>
        <h2 className="Hedingsa">Data Security</h2>
        <p className="SetSubteag">
          We implement reasonable technical and organizational measures to
          protect your personal data from unauthorized access, disclosure,
          alteration, or destruction. While we strive to safeguard your
          information, no data transmission over the internet is entirely
          secure. Therefore, we cannot guarantee the absolute security of your
          data.
        </p>
        <h2 className="Hedingsa">International Transfers</h2>
        <p className="SetSubteag">
          If you are accessing our website from outside India, please be aware
          that your personal information may be transferred to, stored, and
          processed in India or other countries where our servers or service
          providers are located. By using our website, you consent to the
          transfer of your data across borders.
        </p>
        <h2 className="Hedingsa">Your Rights</h2>
        <p className="SetSubteag">
          You have the right to access, update, correct, or delete your personal
          data in our records. If you wish to exercise these rights, please
          contact us at career@quarec.com.
        </p>
        <h2 className="Hedingsa">Changes to this Privacy Policy</h2>
        <p className="SetSubteag">
          We may update this Privacy Policy periodically to reflect changes in
          our practices or legal requirements. Any modifications will be
          effective immediately upon posting the updated Privacy Policy on our
          website.
        </p>
        <h2 className="Hedingsa">Contact Us</h2>
        <p className="SetSubteag">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or your personal information, please contact us at
          career@quarec.com.
        </p>

        <p className="SetSubteag">
          By using our website, you consent to the terms and conditions outlined
          in this Privacy Policy.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Privacypolicy;
