import React from "react";
// import { Puff } from "react-loader-spinner";
import "./Preloader.css";
import Logo from "./Gif/text1.gif";
// import Logo from "./Gif/test2.gif";
// import Respo from "../Main/IMG/respo.gif";

function Preloader() {
  return (
    <div className="Mainloding">
      <div className="preloader">
        {/* <Puff color="#222222" height={150} width={150} /> */}
        <img className="loadingM" src={Logo} alt="..." />
        {/* <img className="loadingR" src={Respo} alt="..." /> */}
      </div>
    </div>
  );
}

export default Preloader;
