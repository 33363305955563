import React, { useEffect } from "react";
import "./Contant.css";
import Services from "./IMG/Services1.png";
import Services2 from "./IMG/Services2.png";
import Services3 from "./IMG/Services3.png";
import Services4 from "./IMG/Services4.png";
import Services5 from "./IMG/Services4.png";

function Contant(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {/* PERMENANT STAFFING SOLUTIONS */}
      <div className="Hd01864">
        <h1 className="Jsf07255">{props.PageName}</h1>
      </div>
      <div className="HDSD8715">
        <p className="FD097531">
          Quarec Resources is India based Recruitment firm providing HR services
          Nationally & Internationally. The main agenda of this company is to
          provide Job Opportunity to Indian In abroad getting best opportunity
          and having earning with safe and secure future.
        </p>
        <p className="FD097531">
          We have seen many Indian Travelling round the globe for Better
          opportunity and Harry International will make them connect through
          best Job available in market globally.
        </p>
        <p className="FD097531">
          Leveraging our unprecedented professional recruiting principles and
          deep understanding of employee recruitment processes, we provide
          comprehensive HR services and recruiting solutions to maximize the
          potential of our customers internationally.
        </p>
        <p className="FD097531">
          Hunting for quality talent may require the recruitment of certain
          skills abroad. As a global organization we hired jobseekers.
        </p>
        <div className="VHD081753">
          <p className="bKs0971">- Middle East</p>
          <p className="bKs0971">- Africa</p>
          <p className="bKs0971">- Mauritius</p>
          <p className="bKs0971">- Canada</p>
          <p className="bKs0971">- UK</p>
          <p className="bKs0971">- Maldives</p>
          <p className="bKs0971">- Australia</p>
          <p className="bKs0971">- US</p>
          <p className="bKs0971">- New Zealand & Many More</p>
        </div>
      </div>
      <div className="CALIyhaap0185">
        <h1 className="HSF08125">OUR INTERNATIONAL RECRUITMENT SERVICES:</h1>
        <p className="FD097531">
          Providing an immigration advice is often a service that we offer as
          part of recruitment package to our prospective and current clients.
          Apart from this, a look at some of the other value added services
          offered by us are:
        </p>
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <img className="GH0817" src={Services} alt="" />
          </div>
          <div className="JHDf082">
            <h1 className="KJGd09762">MANPOWER CONSULTANCY</h1>
            <p className="KLH098761">
              Once the potential candidate has been identified, the next step is
              his selection test, verbal or practical, by the employer or his
              duly authorized representative. To do this, we make full
              arrangements for travel, accommodation, etc., to ensure that the
              candidate reaches the place at the right time.
            </p>
            <p className="KLH098761">
              In the event that we are entrusted with the selection and testing
              of the future employee, we conduct it with the help of a panel of
              technical and professional category experts under the direct
              supervision of our professional executives.
            </p>
            <p className="KLH098761">
              For International hiring most of the time it’s been done by skype
              or it’s also done by verbal & screening the resumes,
            </p>
          </div>
        </div>
      </div>
      <div className="HDSD8715">
        <p className="KFg90827">Advertising</p>
        <p className="KLH098761">
          Although we have a strong data base of candidates, we also advertise
          the required categories in leading English and Regional newspapers on
          all India basis i.e, Times of India, Hindustan Times, Assignment’s
          Abroad, Amar Ujala, Dainik Jagran, Malyalam Manorama and the regional
          publications of the respective cities.
        </p>
        <p className="KLH098761">
          We also upload our client's requirement on Job sites such as
          Naukri.com, Timesjobs.com, and Monster.com & Many more
        </p>
      </div>
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <h1 className="KJGd09762">VISA ASSISTANCE</h1>
            <p className="KLH098761">
              We are aware of the complexity of an international recruiting
              process and specialize in fast and reliable services. The
              experience of processing millions of travel documents ensures that
              your visa is handled quickly and accurately (even overnight) with
              precision and professionalism.
            </p>
          </div>
          <div className="JHDf082">
            <img className="GH0817" src={Services2} alt="" />
          </div>
        </div>
      </div>
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <img className="GH0817" src={Services3} alt="" />
          </div>
          <div className="JHDf082">
            <h1 className="KJGd09762">MEDICAL CHECKUP</h1>
            <p className="KLH098761">
              By complying with immigration standards and the regulations of
              several companies, we ensure that the candidate is physically fit
              for the job. For this we get the medical examination and ask the
              candidate to perform the appropriate tests. A thorough review at
              our site saves a lot of time and unnecessary complications abroad.
            </p>
            <p className="KLH098761">
              Consulate Formalities on receipt of Visa Documents from clients
              the Passport and other required documents are submitted to the
              consulate for Visa stamping
            </p>
          </div>
        </div>
      </div>
      {/* ****** */}
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <h1 className="KJGd09762">TRAVEL & TICKETING ARRANGEMENTS</h1>
            <p className="KLH098761">
              We take full responsibility of the candidate and take care of the
              smallest details, both for our client and for the candidate. With
              our networking and the appropriate connections, we organize
              tickets, accommodation and all paper works. We also receive
              "emigration clearance" from the government department concerned
              for the candidates. The employer may send PTA or transfer the
              necessary travel expenses to: Harry International to facilitate
              travel as planned.
            </p>
          </div>
          <div className="JHDf082">
            <img className="GH0817" src={Services4} alt="" />
          </div>
        </div>
      </div>
      {/* ****** */}
      {/* ****** */}
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <img className="GH0817" src={Services5} alt="" />
          </div>
          <div className="JHDf082">
            <h1 className="KJGd09762">ORIENTATION PROGRAM</h1>
            <p className="KLH098761">
              Once the candidate has been selected, we will conduct an
              orientation program prior to his final departure, in which he will
              be familiar with various important aspects such as the future
              organization's work culture, industrial practices, security
              issues, labor laws and other relevant issues, such as The Time to
              Adapt in the Job is minimal.
            </p>
            <p className="KLH098761">
              We act as intermediaries and mediators, so our tasks also extend
              to the candidate. In line with this, we provide them with the
              tools and resources to make their journey a success. We'll put
              together a tailor-made package for you that offers the best
              combination of pricing, service and information.
            </p>
          </div>
        </div>
      </div>
      {/* ****** */}
      <div className="CALIyhaap0185">
        <h1 className="KJG8763">HOW WE DO IT?</h1>
        <p className="KLH0987610">
          ➢ We have an excellent database of international internships in all
          major areas of work.
        </p>
        <p className="KLH0987610">
          ➢ We have experienced employees with extensive knowledge of
          international industries
        </p>
        <p className="KLH0987610">
          ➢ We work with several international recruitment firms to help with
          immigration and international law.
        </p>
        <p className="KLH0987610">
          ➢ Our warm relationships with airlines, travel agencies, government
          agencies and consultants / embassies are an added benefit.
        </p>
        <h1 className="KJG8763">SOURCING</h1>
        <div className="KJGD98735">
          <p className="KLH0987610">
            With our tremendous networking and a sound data bank of
            approximately 1,00,000 potential candidates from various categories
            like:
          </p>
          <p className="KLH0987610">➢ Call centre jobs</p>
          <p className="KLH0987610">➢ IT professionals</p>
          <p className="KLH0987610">➢ Sales executives</p>
          <p className="KLH0987610">➢ EDP managers</p>
          <p className="KLH0987610">➢ Export executives</p>
          <p className="KLH0987610">➢ Computer operator</p>
          <p className="KLH0987610">➢ Receptionist</p>
          <p className="KLH0987610">➢ Admin / HR executives</p>
          <p className="KLH0987610">➢ Secretaries</p>
          <p className="KLH0987610">➢ Technical</p>
          <p className="KLH0987610">➢ Engineers</p>
          <p className="KLH0987610">➢ Accountants</p>
          <p className="KLH0987610">➢ Purchase officers</p>
          <p className="KLH0987610">➢ Production managers</p>
        </div>
      </div>
    </>
  );
}

export default Contant;
