import React, { useEffect } from "react";
import Nav from "../Navigation/NavBar";
import AboutContant from "./AboutContant";
import WhyChoose from "../Home/WhyChoose/WhyChoose";
import OurProcess from "../Home/OurProcess/OurProcess"; // Fixed the path typo
import Company from "../AboutUs/Company";
import Footer from "../Footer/Footer";
import Directors from "./OurDirectors/Directors";
import Opration from "./OperationsHead/Oprations";
import withAnimation from "../hoc/withAnimation"; // Adjust the import path if necessary

// Wrap components with withAnimation HOC
const AnimatedAboutContant = withAnimation(AboutContant, 'left');
const AnimatedWhyChoose = withAnimation(WhyChoose, 'right');
const AnimatedOurProcess = withAnimation(OurProcess, 'left');
const AnimatedDirectors = withAnimation(Directors, 'right');
const AnimatedOpration = withAnimation(Opration, 'left');
const AnimatedCompany = withAnimation(Company, 'right');
const AnimatedFooter = withAnimation(Footer, 'left');

function About() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Nav />
      <AnimatedAboutContant />
      <AnimatedWhyChoose />
      {/* <AnimatedOurProcess /> */}
      <AnimatedDirectors />
      {/* <AnimatedOpration /> */}
      <AnimatedCompany />
      <AnimatedFooter />
    </>
  );
}

export default About;
