import React from "react";
import Head from "./IMG/Rectangle 34.png";
import { Icon } from "@iconify/react";
import linkedinIcon from "@iconify/icons-devicon/linkedin";
import { useNavigate } from "react-router-dom";
import "./Opration.css";

function Oprations() {
  let navigate = useNavigate();
  const DMam = () => {
    let url = "https://www.linkedin.com/in/aditi-pandya-938322b2/";
    window.open(url, "_blank");
  };
  return (
    <>
      <h2 className="RFCeEFC">Operations Head</h2>
      <div className="OprationMain">
        <div className="container1">
          <div className="grip">
            <article className="article">
              <figure className="article__figure1">
                <img className="article__cover" src={Head} />
                <figcaption className="article__caption">
                  <div className="articaleborderset">
                    <div className="subborder">
                      <p className="article__info">Ms.</p>
                      <h2 className="article__title">Aditi Pandya</h2>
                      <p className="article__info">Operations Manager</p>
                      <p className="article__info_min">
                        At Quarec Group Of Companies
                      </p>
                      <Icon
                        onClick={DMam}
                        // style={{ visibility: "hidden" }}
                        className="article__Icon"
                        icon={linkedinIcon}
                      />
                    </div>
                  </div>
                </figcaption>
              </figure>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}

export default Oprations;
