import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

const AnimatedDiv = styled.div`
  opacity: 0;
  transition: opacity 1s ease-out, transform 1s ease-out;
  transform: translateY(80px); /* Initial position */

  ${(props) =>
        props.inView &&
        css`
      opacity: 1;
      transform: translateY(0); /* Move to top */
    `}
`;

const withAnimation = (WrappedComponent) => {
    return (props) => {
        const { ref, inView } = useInView({
            triggerOnce: true,
            threshold: 0.1,
        });

        return (
            <AnimatedDiv ref={ref} inView={inView}>
                <WrappedComponent {...props} />
            </AnimatedDiv>
        );
    };
};

export default withAnimation;
