import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./NewBlog.css";
import NavBar from "../../Navigation/NavBar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react";
import arrowLeft from "@iconify/icons-mdi/arrow-left";
import Blog1 from "../IMG/C1.png";
import Blog2 from "../IMG/C2.png";
import Blog3 from "../IMG/C3.png";
import Blog4 from "../IMG/C4.png";
import Blog5 from "../IMG/C5.png";
import Blog6 from "../IMG/C6.png";
import Blog7 from "../IMG/M7.png";

function QuationTwo() {
  let navigate = useNavigate();
  const GoBack = () => {
    let path = `/Blog`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="subtrnthblog">
        <h1 className="texico">
          <Icon onClick={GoBack} className="iconicals" icon={arrowLeft} />
          Tips for Better Job Hunting
        </h1>
        {/* <p className="DateF">16/08/2023 12:30 PM</p> */}
        <div className="responssetpup">
          <ul>
            {/* <p className="uliu">
          Crafting a compelling pitch deck is a critical component in an
          entrepreneur's quest for funding.
        </p> */}
            <p className="uiliuo">
              Unless you are one of the lucky few who works in a high-demand
              career, finding a new job can be a challenging and frustrating
              experience.
            </p>
            <p className="uiliuo">
              You can make the job search a bit easier on yourself if you use
              proactive strategies for finding a new job- and the tips for
              finding a new job included below are applicable to all job
              seekers, from those just starting out to experienced candidates
              who need a quick refresher.
            </p>
            <p className="uiliuo">
              <b>Few best tips for finding a new job at any career level :</b>
            </p>
          </ul>
          <ol>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog1} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">Get Clear on What You Want</li>
                <p className="uiliuo1">
                  Before starting your job search, take the time to reflect on
                  your strengths and weaknessess and the type of work you enjoy
                  doing. The better you know yourself, the more likely you'll
                  find a new job that provides you with greater satisfcation.
                  What do you want in a job? What' the most important, title,
                  money, promotion, the work itself, location or company
                  culture?
                </p>

                <li className="uliu">Research Your Target Companies</li>
                <p className="uiliuo1">
                  Once you know what you want, it's time to find out what
                  companies you're applying for want. A great tip for finding a
                  new job is to investigate a company's Glassdoor page. It will
                  help you get a feel for their company culture, figure out what
                  questions they commonly ask in interviews, and even discover
                  what salary you're likely to be paid.
                </p>
                <li className="uliu">Tailor Your Resume to Each Job</li>
                <p className="uiliuo1">
                  Your resume is still one of the most critical tools of a job
                  search. A lot of resumes seen are full of responsibilities and
                  jobseekers send the same resume to various openings. One of
                  the best tips for finding a new job is to have an
                  achievement-oriented resume that includes quantifiable
                  achievements that are relevant to the job you're applying for.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <p className="uiliuo1">
                  Make yourself an obvious fit. Study the words and phrases that
                  are used in the job description. Make sure you include them in
                  your resume (provided you have that experience, of course).
                  Tailor your resume to each job - the recruiter should know
                  within a few seconds of looking at your resume that you have
                  the skills they are looking for.
                </p>
                <li className="uliu">Create Your Online Career Brand</li>
                <p className="uiliuo1">
                  Building your brand simply means showcasing your experience
                  and passion online where employers searching the Web can find
                  it. Most recruiters, including myself, use LinkedIn to your
                  full advantage. It's a great resource for finding people
                  working at companies that interest you and also for
                  positioning yourself to be found by recruiters and hiring
                  managers with relevant openings.
                </p>

                <li className="uliu">Get Organised</li>
                <p className="uiliuo1">
                  Before your start applying for jobs or interviewing with
                  employers, take a moment to develop a syste, that works for
                  you in organizing your job search. A simple spreadsheet works
                  best for many to keep a track of the jobs you've applied for,
                  where you have been invited to interview, etc.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog2} alt="" />
              </div>
            </div>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog3} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">
                  Build, Cultivate, And Ultilize Your Network of Contacts
                </li>
                <p className="uiliuo1">
                  For vast majority of jobseekers, a large and strong network of
                  contacts- people who know you and want to help you uncover job
                  leads- results in more job opportunities. Networking- in
                  person and online- is essential to your success in your job
                  search.
                </p>
                <p className="uiliuo1">
                  It also helps you to get a good idea of what is out there and
                  available, so you can be more strategic in your job search.
                  Don't be afraid to reach out to people on LinkedIn, and if you
                  know something working at a compant that interests you, ask
                  for refferal. Hiring Managers would prefer to interview people
                  who came recommended before snorting through resumes arriving
                  via a career website.
                </p>
                <li className="uliu">
                  Don't Limit Yourself To Online Applications
                </li>
                <p className="uiliuo1">
                  If you rely only on submitting online applications, you could
                  be looking for a job for a very long time. By the time you
                  apply, the company might be in the final interview stage, or
                  the job might have been filled. Contact companies that
                  interest you directly- you might get in contact with an
                  internal recruiter or schedule informational interviews with
                  people who work in those companies. Ideally, you want to be
                  known to the people who might influence you getting your foot
                  in the door.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <li className="uliu">
                  Aim To Complete A Few Job- Related Goals Daily
                </li>
                <p className="uiliuo1">
                  It take a great deal of time and effort to find a new job. In
                  a long job search, it's easy to get discouraged and
                  distracted, but by focusing on achieving daily goals you can
                  motivate yourself while also building a foundation for
                  success.
                </p>
                <li className="uliu">Be Kind To Yourself</li>
                <p className="uiliuo1">
                  Looking for a job can be stressful.So, take sometime to
                  meditate, exercise, watch a movie or whatever it is that helps
                  you unwind. Create a good support network- having people to
                  brainstrom with or vent your frustrations to will help the
                  process be less painful.
                </p>
                <li className="uliu">
                  Develop Examples And Stories That Showcase Your Skills
                </li>
                <p className="uiliuo1">
                  This is one of the main tips for finding a new job. People
                  remember stories, so your goal should be developing a set of
                  interview stories you can use in networking meetings or job
                  interviews that clearly demostrate your skills, achievements,
                  and passion for your work. Be memorable!
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog4} alt="" />
              </div>
            </div>

            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog5} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">Prepare for all Job Interviews</li>
                <p className="uiliuo1">
                  Before you get called for your first interview, develop
                  responses for common interview questions, and then practice
                  them- ideally using the mock- interviewing technique with a
                  friend, network contact, or interview coach. The more prepared
                  you are for the interview, the more comfortable you'll be- and
                  the more likely you'll succeed.
                </p>

                <li className="uliu">
                  Write Thank- You Notes After Interviews To All Interviewers
                </li>
                <p className="uiliuo1">
                  A quick note (by email is fine) of thanks that emphasizes your
                  interest and fit with the job and employer will not get you
                  the job offer, but it will help make you stand out from the
                  majority of jobseekers who do not bother with this simple act
                  of courtesy.
                </p>
                <li className="uliu">
                  Continue Following Up With Hiring Managers
                </li>
                <p className="uiliuo1">
                  Your work is not done once the interview is complete or the
                  thank-you note sent. Following up with hiring manager
                  regularly shows your interest and enthusiasm for the job. The
                  key is doing so in a way that is professional while not making
                  you sound pesky or needy.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <li className="uliu">
                  Expect The Job Search To Take Longer Than You Think
                </li>
                <p className="uiliuo1">
                  You can hope to have a new job within a short period, but the
                  likely reality is that it might take months to find the right
                  opportunity and get offered the position. You should mentally
                  prepare yoursef for a long battle and then you can happily
                  surprised if you are one of the lucky few whose job search is
                  short.
                </p>
                <p className="uiliuo1">
                  Anticipate a prolonged job search journey. Patience is key as
                  the process often exceeds initial estimates. Factors like
                  competition and fit play roles. Remain persistent, flexible,
                  and open-minded. Stay prepared for unexpected delays and focus
                  on enhancing skills during the extended quest for employment
                  success.
                </p>
                <p className="uiliuo1">
                  Embarking on a job search demands a realistic outlook. The
                  path to securing the right opportunity often stretches beyond
                  one's initial projections. Variables such as industry shifts,
                  economic conditions, and personal preferences intertwine,
                  influencing the timeline. Prevailing competition amplifies the
                  challenge, requiring a resilient mindset. Adapting
                  expectations and staying adaptable is crucial.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog6} alt="" />
              </div>
            </div>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default QuationTwo;
