import React from "react";
import "./Directors.css";
import Director1 from "./IMGS/Director2.png";
import Director2 from "./IMGS/Director1.png";
// import Director3 from "./IMGS/DR.svg";
import Director3 from "./IMGS/Full.png";
import { Icon } from "@iconify/react";
import linkedinIcon from "@iconify/icons-devicon/linkedin";
import { useNavigate } from "react-router-dom";

function Directors() {
  let navigate = useNavigate();
  const DR = () => {
    let url = "https://www.linkedin.com/in/dineshsinh-rajput-475383142/";
    window.open(url, "_blank");
  };
  const DSir = () => {
    let url = "https://www.linkedin.com/in/nikunj-patel-52770416a";
    window.open(url, "_blank");
  };
  const DMam = () => {
    let url = "https://www.linkedin.com/in/khushbu-patel-395116120/";
    window.open(url, "_blank");
  };
  return (
    <>
      <h2 className="RFCeEFC">OUR DIRECTORS</h2>
      <div classNameName="DirectoreMain">
        <div className="container">
          <div className="grid">
            <article className="article">
              <figure className="article__figure">
                <img className="article__cover" src={Director3} />
                <figcaption className="article__caption">
                  <div className="articaleborder">
                    <div className="subborder">
                      <p className="article__info">Mr.</p>
                      <h2 className="article__title">Dinesh Rajput</h2>
                      <p className="article__info">Founder </p>
                      <p className="article__info_min">
                        At Quarec Group Of Companies
                      </p>
                      <Icon
                        onClick={DR}
                        // style={{ visibility: "hidden" }}
                        className="article__Icon"
                        icon={linkedinIcon}
                      />
                    </div>
                  </div>
                </figcaption>
              </figure>
            </article>
            <article className="article">
              <figure className="article__figure">
                <img className="article__cover" src={Director2} />
                <figcaption className="article__caption">
                  <div className="articaleborder">
                    <div className="subborder">
                      <p className="article__info">Mrs.</p>
                      <h2 className="article__title">Khushbu Patel</h2>
                      <p className="article__info">Director & Founder </p>
                      <p className="article__info_min">
                        At Quarec Group Of Companies
                      </p>
                      <Icon
                        onClick={DMam}
                        // style={{ visibility: "hidden" }}
                        className="article__Icon"
                        icon={linkedinIcon}
                      />
                    </div>
                  </div>
                </figcaption>
              </figure>
            </article>
            <article className="article">
              <figure className="article__figure">
                <img className="article__cover" src={Director1} />
                <figcaption className="article__caption">
                  <div className="articaleborder">
                    <div className="subborder">
                      <p className="article__info">Mr.</p>
                      <h2 className="article__title">Nikunj Patel</h2>
                      <p className="article__info">Director & Founder </p>
                      <p className="article__info_min">
                        At Quarec Group Of Companies
                      </p>
                      <Icon
                        onClick={DSir}
                        className="article__Icon"
                        icon={linkedinIcon}
                        // style={{ visibility: "hidden" }}
                      />
                    </div>
                  </div>
                </figcaption>
              </figure>
            </article>
          </div>
        </div>
      </div>
    </>
  );
}

export default Directors;
