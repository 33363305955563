import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import JobApplication from "./JobApplication";
import Footer from "../Footer/Footer";

function Apply() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <JobApplication />
      <Footer />
    </>
  );
}

export default Apply;
