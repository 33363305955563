import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import Benner from "./Benner";
import Services from "./Services/Services";
import Industrie from "./Industrie/Industrie";
import WhyChoose from "./WhyChoose/WhyChoose";
import ClientsAndCandidates from "./ClientsAndCandidates/ClientsAndCandidates";
import OurProcess from "./OurProcess/OurProcess";
import Testimonials from "../Testimonials/Testimonials";
import GetInTouch from "../GetInTouch/GetInTouch";
import Footer from "../Footer/Footer";
import WhatsAppWidgetComponent from "../Whatsapp/WhatsAppWidgetComponent";
import withAnimation from "../hoc/withAnimation"

// Wrap components with withAnimation HOC
const AnimatedBenner = withAnimation(Benner, 'left');
const AnimatedServices = withAnimation(Services, 'right');
const AnimatedIndustrie = withAnimation(Industrie, 'left');
const AnimatedWhyChoose = withAnimation(WhyChoose, 'right');
const AnimatedClientsAndCandidates = withAnimation(ClientsAndCandidates, 'left');
const AnimatedOurProcess = withAnimation(OurProcess, 'right');
const AnimatedTestimonials = withAnimation(Testimonials, 'left');
const AnimatedGetInTouch = withAnimation(GetInTouch, 'right');
const AnimatedFooter = withAnimation(Footer, 'left');

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavBar />
      <AnimatedBenner />
      <AnimatedServices />
      <AnimatedIndustrie />
      {/* <AnimatedWhyChoose /> */}
      <AnimatedClientsAndCandidates />
      <AnimatedOurProcess />
      <AnimatedTestimonials />
      <AnimatedGetInTouch />
      <AnimatedFooter />
      <WhatsAppWidgetComponent />
    </>
  );
}

export default Home;
