import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import Footer from "../Footer/Footer";
import Services5 from "./IMG/Services5.png";
import Services6 from "./IMG/Services6.png";
import Services7 from "./IMG/Services7.png";
import Services8 from "./IMG/Services8.png";
import Services9 from "./IMG/Services9.png";
import Services10 from "./IMG/Services10.png";

function International() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiutk">
          <div className="JHDf082">
            <h1 className="KJGd09762">International Recruitment</h1>
            <img className="GH0817" src={Services5} alt="" />
          </div>
          <div className="JHDf082">
            <p className="KLH098761">
              At Quarec Resources, we specialize in international recruitment,
              assisting companies in sourcing and hiring talented professionals
              from around the world. Here's an overview of our international
              recruitment process:
            </p>
            <p className="KLH098761">
              Client Consultation: We collaborate closely with our clients to
              understand their specific staffing needs, including the desired
              skills, qualifications, and experience required for the positions
            </p>
            <p className="KLH098761">
              Talent Sourcing: Our global network and extensive resources enable
              us to source candidates from various countries and regions. We
              employ various strategies such as targeted advertising,
              networking, and leveraging online platforms to attract top
              international talent.
            </p>
          </div>
        </div>
      </div>

      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <p className="KLH0987612">
              Candidate Screening: We conduct rigorous screening processes,
              including resume review, interviews, and assessments, to evaluate
              the candidates' qualifications, language proficiency, cultural
              fit, and suitability for the roles they are being considered for.
            </p>
            <p className="KLH0987612">
              Visa and Immigration Support: We assist candidates with the visa
              and immigration processes, ensuring compliance with the host
              country's immigration laws. We provide guidance and support to
              navigate the complexities of work permits, visa applications, and
              any other necessary documentation.
            </p>
            <p className="KLH0987612">
              Cultural Orientation and Support: We provide cultural orientation
              to help candidates adapt to their new work environment and local
              customs. This includes providing information on local laws,
              customs, and workplace etiquette, helping them seamlessly
              integrate into the client's organization.
            </p>
          </div>
          <div className="JHDf0820">
            <img className="GH08170" src={Services6} alt="" />
          </div>
        </div>
      </div>
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiutk1">
          <div className="JHDf082">
            <img className="GH0817" src={Services7} alt="" />
          </div>
          <div className="JHDf082">
            <p className="KLH098761">
              Travel and Relocation Logistics: We handle travel arrangements,
              such as flight bookings and accommodation, to facilitate the
              candidate's relocation. We ensure a smooth transition by assisting
              with logistics and addressing any concerns or queries related to
              the relocation process.
            </p>
            <p className="KLH098761">
              On boarding and Integration: We work closely with our clients to
              facilitate a comprehensive onboarding process for international
              hires. This includes familiarizing them with company policies,
              procedures, and team dynamics to ensure a successful integration
              into the client's organization.
            </p>
            <p className="KLH098761">
              Ongoing Support: We maintain regular communication with both the
              client and the candidate to ensure a positive experience and
              address any issues or concerns that may arise during their
              employment. We provide ongoing support to foster a productive and
              satisfying working relationship
            </p>
          </div>
        </div>
      </div>

      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <h1 className="Respohead">Executive Search</h1>
            <p className="KLH0987612">
              Quarec Resources offers dedicated IT resources and teams to
              support a wide range of projects. Our skilled IT professionals are
              available to provide specialized expertise and assistance tailored
              to your specific needs. Whether you require support for software
              development, system administration, network infrastructure,
              cybersecurity, or any other IT-related projects,
            </p>
            <p className="KLH0987612">
              our dedicated resources are ready to contribute their knowledge
              and skills. We ensure that our IT teams are equipped with the
              latest technologies and tools to deliver efficient and effective
              solutions. Partner with Quarec Resources to access dedicated IT
              resources that will drive the success of your projects.
            </p>
          </div>
          <div className="JHDf0820">
            <h1 className="KJGd0976213">Executive Search</h1>
            <img className="GH08170" src={Services8} alt="" />
          </div>
        </div>
      </div>
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiutk1">
          <div className="JHDf082">
            {/* <h1 className="KJGd09762">Payroll Management</h1> */}
            <img className="GH0817" src={Services9} alt="" />
          </div>
          <div className="JHDf082">
            <p className="KLH098761">
              But it doesn't stop there. We provide personalized support
              throughout the entire journey, from candidate assessment to
              negotiation and onboarding. We ensure a seamless transition,
              allowing your newly appointed executive to hit the ground running
              and make an immediate impact.
            </p>
            <p className="KLH098761">
              We believe that exceptional leadership is the key to unlocking
              unparalleled success. Trust us to uncover the extraordinary
              leaders who will guide your organization towards a future of
              innovation, growth, and extraordinary achievements. Experience the
              Quarec difference and embark on a transformative journey with our
              executive search services.
            </p>
          </div>
        </div>
      </div>

      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <h1 className="Respohead">Bulk Hiring</h1>
            <p className="KLH0987612">
              Quarec Resources excels in efficient bulk hiring, providing
              streamlined solutions for large-scale workforce needs. With an
              extensive talent pool, we quickly identify qualified candidates.
              Our scalable strategies, thorough screening, and timely turnaround
              ensure quality hires.
            </p>
            <p className="KLH0987612">
              We adapt to your requirements, prioritizing cultural fit. We
              provide comprehensive on boarding support and dedicated account
              management. Trust us to handle your bulk hiring with speed,
              efficiency, and a focus on quality. Experience seamless and
              successful bulk recruitment with Quarec Resources.
            </p>
          </div>
          <div className="JHDf0820">
            <h1 className="KJGd0976213">Bulk Hiring</h1>
            <img className="GH08170" src={Services10} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default International;
