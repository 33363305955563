import React, { useState } from "react";
import "./Footer.css";
import "../Navigation/Btn.css";
import LOGO from "../Navigation/IMG/LOGO1.png";
import { Icon } from "@iconify/react";
import linkedinBoxFill from "@iconify/icons-ri/linkedin-box-fill";
import instagramAlt from "@iconify/icons-uil/instagram-alt";
import socialFacebook from "@iconify/icons-foundation/social-facebook";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

function Footer() {
  const linkedin = () => {
    let path = "https://www.linkedin.com/company/quarec-resources-pvt-ltd/";
    window.open(path, "_blank");
  };
  const instagram = () => {
    let path = "https://www.instagram.com/quarecresources/";
    window.open(path, "_blank");
  };
  const facebook = () => {
    let path = "https://www.facebook.com/quarecresources/";
    window.open(path, "_blank");
  };
  const [Email, setEmail] = useState("");
  const [EmailError, setEmailError] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmits = (event) => {
    event.preventDefault();

    let hasErrors = false;

    if (!Email.trim()) {
      setEmailError("Email is required");
      hasErrors = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Please enter a valid email address");
      hasErrors = true;
    } else {
      setEmailError("");
    }
    if (!hasErrors) {
      console.log("form Submited", {
        Email,
      });
      const notify = () =>
        toast.success("Thank you for subscribing Our Newsletter !", {
          bodyClassName: "my-toast-body",
          position: "top-right",
          icon: <FaCheck color="#2F7175" />,
          // toastBody.style.borderBottomColor = "red";
          style: {
            backgroundColor: "2px solid red",
          },
          progressBarStyle: { backgroundColor: "#2F7175" },
        });
      notify();
      setEmail("");
    }
  };
  return (
    <>
      <div className="Vb081wq">
        <div className="JHDF982">
          <div className="Vagd1">
            <img className="Gak091" src={LOGO} alt="" />
            <p className="gsdfSqaz">
              Connecting talent
              <br /> to opportunity - <br />
              “Your pathway to success.”
            </p>
          </div>
          <div className="Hrapavil">
            <div className="Vagd2">
              <h2 className="JDF09237">Quick Links</h2>
              <div className="GDFiww0891">
                <Link to="/" className="VajHov">
                  Home
                </Link>
                <Link to="/AboutUs" className="VajHov">
                  About Us
                </Link>
                <Link to="/Blog" className="VajHov">
                  Blogs
                </Link>
                <Link to="/ContactUs" className="VajHov">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="Vagd">
              <h2 className="JDF09237">Services</h2>
              <div className="GDFiww0891">
                <Link to="/Services" className="VajHov">
                  Permanent Staffing
                </Link>
                <Link to="/Services" className="VajHov">
                  Temporary Staffing
                </Link>
                <Link to="/Services" className="VajHov">
                  Payroll Management
                </Link>
                <Link to="/Services" className="VajHov">
                  International Recruitment
                </Link>
                <Link to="/Services" className="VajHov">
                  Dedicated IT Resources
                </Link>
                <Link to="/Services" className="VajHov">
                  Executive Search
                </Link>
                <Link to="/Services" className="VajHov">
                  Bulk Hiring
                </Link>
              </div>
            </div>
          </div>
          <div className="Hrapavil">
            <div className="Vagd">
              <h2 className="JDF09237">Important Links</h2>
              <div className="GDFiww0891">
                <Link to="/Privacypolicy" className="VajHov">
                  Privacy Policy
                </Link>
                <Link to="/TermsConditions" className="VajHov">
                  Terms & Conditions
                </Link>
              </div>
              <div className="V0911021WJ">
                <h2 className="JDF09237">Social Links</h2>
                <div className="VCoau">
                  <Icon
                    onClick={linkedin}
                    className="Vaopl"
                    icon={linkedinBoxFill}
                  />
                  <Icon
                    onClick={instagram}
                    className="Vaopl"
                    icon={instagramAlt}
                  />
                  <Icon
                    onClick={facebook}
                    className="Vaopl"
                    icon={socialFacebook}
                  />
                </div>
              </div>
            </div>
            <div className="Vagd">
              <h2 className="JDF09237">Stay Updated</h2>
              <div className="GDFiww0891">
                <p className="Vd091">Subscribe To Our Newsletter </p>
                <form
                  // onSubmit={handleSubmits}
                  action="https://quarecres.mcxcontrol.com/api/subscribers.php"
                  method="post"
                  enctype="multipart/form-data"
                >
                  <input
                    className="Vakjs01"
                    type="email"
                    placeholder="Enter Your Mail I’d"
                    value={Email}
                    onChange={handleEmailChange}
                    required
                    name="email"
                  />
                  {EmailError && <div className="Baolq">{EmailError}</div>}
                  <button className="shin-btn">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BV0927">
        <p>Copyright © 2024 Quarec Resources | All rights reserved.</p>
      </div>
    </>
  );
}

export default Footer;
