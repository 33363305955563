import React, { useState, useEffect } from "react";
import "./NumberCounter.css"; // import the CSS file for styling

function NumberCounter(props) {
  const [count, setCount] = useState(0);
  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > props.startAt && !startCounting) {
        setStartCounting(true);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [props.startAt, startCounting]);

  useEffect(() => {
    if (count >= props.stopAt) {
      return; // exit the effect without setting up the interval
    }

    const intervalId = setInterval(() => {
      setCount(count + 1);
    }, 100);

    return () => clearInterval(intervalId);
  }, [count, props.stopAt]);

  return (
    <div className="number-counter">
      <span className="count">{count}</span>
      <span className="VCf0971">+</span>
    </div>
  );
}

export default NumberCounter;
