import React from "react";
import "./Company.css";
import Rectangle1 from "./IMG/Rectangle1.svg";
import Rectangle2 from "./IMG/Rectangle2.svg";
import Rectangle3 from "./IMG/Rectangle3.svg";
import Rectangle4 from "./IMG/Rectangle4.svg";
import Rectangle5 from "./IMG/Rectangle5.svg";
import Rectangle6 from "./IMG/Rectangle6.svg";
import Rectangle7 from "./IMG/Rectangle7.svg";
import Rectangle8 from "./IMG/Rectangle8.svg";
import Rectangle9 from "./IMG/Rectangle9.svg";
import Rectangle10 from "./IMG/Rectangle10.svg";
import Rectangle11 from "./IMG/Rectangle11.svg";
import Rectangle12 from "./IMG/Rectangle12.svg";
// Second Slider Images
import Slider1 from "./IMG/Slider1.svg";
import Slider2 from "./IMG/Slider2.svg";
import Slider3 from "./IMG/Slider3.svg";
import Slider4 from "./IMG/Slider4.svg";
import Slider5 from "./IMG/Slider5.svg";
import slider6 from "./IMG/Slider6.svg";
import { saveAs } from "file-saver";

const PDF_FILE = "https://www.quarec.com/Quarec Resources- Company Profile.pdf";

const Company = () => {
  const downloadFileAtUrl = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag); // <-- Corrected 'Document' to 'document'
    aTag.click();
    aTag.remove();
  };

  var settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      {/* <h1 className="V01plqa">LIFE AT QUAREC</h1>
      <div className="slider">
        <div className="slide-track">
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle1} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle2} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle3} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle4} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle5} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle6} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle7} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle8} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle9} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle10} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle11} alt="" />
          </div>
          <div className="slide">
            <img className="ForSlidImg" src={Rectangle12} alt="" />
          </div>
        </div>
      </div> */}
      <h1 className="V01plqa">COMPANY PROFILE</h1>

      <div className="Bo0pq1">
        <div className="KG0174">
          <div id="slider">
            <figure>
              <img src={Slider1} alt="" />
              <img src={Slider2} alt="" />
              <img src={Slider3} alt="" />
              <img src={Slider4} alt="" />
              <img src={Slider5} alt="" />
            </figure>
          </div>
        </div>
        <div className="HDG081">
          <p className="HAa01756">
            Quarec Resources Pvt. Ltd. is a dynamic HR Consultancy and Placement
            Services company, connecting job seekers and organizations. Our
            promise is to provide efficient, integrity-driven Human Resources
            solutions, delivering preferred results with a passion for
            excellence. We aspire to be the bridge that fosters success and
            growth for both candidates and clients.
            <div className="JDH0175">
              <button
                onClick={() => {
                  downloadFileAtUrl(PDF_FILE);
                }}
                className="shiney-btn"
              >
                Download
              </button>
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default Company;
