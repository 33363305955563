import React, { useEffect } from "react";
import Nav from "../Navigation/NavBar";
import Contant from "./Contant";
import Footer from "../Footer/Footer";

function TemporaryStaff() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Nav />
      <Contant PageName="TEMPORARY STAFFING SOLUTIONS" />
      <Footer />
    </>
  );
}

export default TemporaryStaff;
