import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./NewBlog.css";
import NavBar from "../../Navigation/NavBar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react";
import arrowLeft from "@iconify/icons-mdi/arrow-left";
import Blog1 from "../IMG/new/FirstDay1.jpg";
import Blog2 from "../IMG/new/FirstDay2.jpg";
import Blog3 from "../IMG/new/FirstDay3.jpg";

function Firstday() {
  let navigate = useNavigate();
  const GoBack = () => {
    let path = `/Blog`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="subtrnthblog">
        <h1 className="texico">
          <Icon onClick={GoBack} className="iconicals" icon={arrowLeft} />
          MASTERING THE ART OF BUILDING CONNECTIONS IN YOUR FIRST 90 DAYS AT A
          NEW JOB
        </h1>
        {/* <p className="DateF">By Tom Cornell</p> */}
        <p className="DateF">7/12/2023 6:50 PM</p>
        <div className="responssetpup">
          <ul>
            {/* <p className="uliu">
          Crafting a compelling pitch deck is a critical component in an
          entrepreneur's quest for funding.
        </p> */}
            <p className="uiliuo">
              <b>Starting A New Job Can Be Both Exciting And Challenging.</b>
            </p>
            <p className="uiliuo">
              Establishing strong relationships can not only enhance your job
              satisfaction but also contribute to your professional growth.
              Here’s a guide on how to master the art of building connections in
              the first 90 days at your new job:
            </p>
          </ul>
          <ul>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog1} alt="" />
              </div>
              <div className="supset2">
                {/* <li className="uliu">Define Clear Job Requirements:</li> */}
                <p className="uliu">Be Approachable and Open</p>
                <p className="uiliuo1">
                  Create a positive first impression by being approachable and
                  open to meeting new people. Smile, make eye contact, and offer
                  a firm handshake. Actively listen during introductions, and
                  show genuine interest in learning about your colleagues.
                </p>
                <p className="uliu">Initiate Conversations</p>
                <p className="uiliuo1">
                  Don’t wait for others to come to you. Take the initiative to
                  introduce yourself to your colleagues. Strike up casual
                  conversations, not just about work but also about common
                  interests, hobbies, or experiences. This helps in establishing
                  a more personal connection.
                </p>
                <p className="uliu">Attend Social Events</p>
                <p className="uiliuo1">
                  Participate in{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://shecancode.io/attend-events/"
                  >
                    social events
                  </a>{" "}
                  organised by the company or team. Whether it’s a coffee break,
                  team lunch, or after-work gathering, these events provide a
                  relaxed setting to get to know your colleagues on a more
                  personal level.
                </p>
                <p className="uliu">Join Work-related Groups</p>
                <p className="uiliuo1">
                  Many workplaces have internal groups or forums related to
                  specific projects, interests, or activities. Joining these
                  groups not only helps you understand more about your job but
                  also provides opportunities to connect with colleagues who
                  share similar professional interests.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <p className="uliu">Ask Questions</p>
                <p className="uiliuo1">
                  Demonstrate your curiosity by asking thoughtful questions.
                  Whether it’s about a specific project, team dynamics, or the
                  company culture, asking questions shows your eagerness to
                  learn and your interest in your colleagues’ perspectives.
                </p>
                <p className="uliu">Offer Help and Collaborate</p>
                <p className="uiliuo1">
                  Be proactive in offering assistance when a colleague seems
                  overwhelmed or in need of support. Collaborating on tasks
                  fosters a sense of teamwork and helps you build rapport with
                  your coworkers.
                </p>
                <p className="uliu">Express Gratitude</p>
                <p className="uiliuo1">
                  When someone helps you or goes out of their way to make you
                  feel welcome, express your gratitude. A simple thank-you note
                  or a verbal acknowledgment can go a long way in establishing a
                  positive connection.
                </p>{" "}
                <p className="uliu">Be Mindful of Company Culture</p>
                <p className="uiliuo1">
                  Observe and understand the{" "}
                  <a
                    href="https://shecancode.io/company-profile/"
                    style={{ textDecoration: "none" }}
                  >
                    company culture
                  </a>
                  . Adapt your communication style and behavior to align with
                  the cultural norms. This demonstrates your respect for the
                  existing work environment.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog2} alt="" />
              </div>
            </div>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog3} alt="" />
              </div>
              <div className="supset2">
                <p className="uliu">Schedule One-on-One Meetings</p>
                <p className="uiliuo1">
                  Take the initiative to schedule one-on-one meetings with your
                  colleagues, especially those with whom you’ll be working
                  closely. Use this time to learn more about their roles, share
                  your background, and discuss how you can collaborate
                  effectively.
                </p>
                <p className="uliu">Follow Up</p>
                <p className="uiliuo1">
                  After initial interactions, follow up with your colleagues.
                  Send a brief email expressing your pleasure in meeting them or
                  referencing something you discussed. This small gesture shows
                  that you value the connection.
                </p>
                <p className="uiliuo1">
                  Building connections in the first 90 days sets the foundation
                  for a positive and collaborative work environment. By being
                  proactive, open, and genuinely interested in your colleagues,
                  you’ll not only integrate seamlessly into the team but also
                  contribute to a more enjoyable and fulfilling professional
                  experience.
                </p>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Firstday;
