import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./NewBlog.css";
import NavBar from "../../Navigation/NavBar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react";
import arrowLeft from "@iconify/icons-mdi/arrow-left";
import Blog1 from "../IMG/new/A2.png";
import Blog2 from "../IMG/new/A1.png";
import Blog3 from "../IMG/new/A3.png";
import Blog4 from "../IMG/new/A4.png";
import Blog5 from "../IMG/new/A6.png";
import Blog6 from "../IMG/M6.png";
import Blog7 from "../IMG/M7.png";

function Quation9() {
  let navigate = useNavigate();
  const GoBack = () => {
    let path = `/Blog`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="subtrnthblog">
        <h1 className="texico">
          <Icon onClick={GoBack} className="iconicals" icon={arrowLeft} />
          Uses of Recruiting Automation in Your Hiring Process
        </h1>
        <p className="DateF">14/08/2023 2:40 PM</p>
        <div className="responssetpup">
          <ul>
            {/* <p className="uliu">
              Crafting a compelling pitch deck is a critical component in an
              entrepreneur's quest for funding.
            </p> */}
            <p className="uiliuo">
              Automation Technology is transforming recruiting. 75% of
              recruiters say technology will play a larger role in their hiring
              process this year- and we can expect that 100% recruitment
              departments will rely on automation to streamline hiring processes
              sooner rather than later. With that said, in an industry that's
              centred almost entirely around people, it may seem counter
              intuitive to automate different parts of your recruiting process
              However, there are actually a number of benefits to doing just
              that, especially when you're hiring at scale.
            </p>
          </ul>
          <ul>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog1} alt="" />
              </div>
              <div className="supset2">
                <p className="uliu">Why recruiting automation?</p>
                <p className="uiliuo1">
                  Recruitment teams benefit from using recruiting automation in
                  many ways. Here are a few of the most impactful advantages.
                </p>
                <li className="uliu">Increased productivity</li>
                <p className="uiliuo1">
                  Automation is not meant to replace recruiters, but rather to
                  empower them to work more efficiently and make better
                  decisions at a faster pace. Automating processes like manual
                  resume screening and interview scheduling allow you to focus
                  on more meaningful work.
                </p>
                <li className="uliu">Improved quality of hire</li>
                <p className="uiliuo1">
                  Recruiting automation not only increases efficiently but also
                  improves the quality of a company's hiring efforts. It allows
                  recruiters to hire candidates based on characteristics
                  predictive for job success. Automation also leads to more
                  consistent hiring decisions, as it removes many variables that
                  are not predictive for job performance. This is especially
                  important in verticals such as retail, quick service
                  restaurants, or contact centers/ BPOs, where recruitment teams
                  have to review and assess hundreds or even thousands of
                  candidates every month.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <li className="uliu">Enhanced candidate experience</li>
                <p className="uiliuo1">
                  Automation technology doesn't only benefit recruiters- it
                  benefits candidates as well! By automating certain steps in
                  the recruitment process, recruiters can focus more on building
                  relationships, while candidates experience a streamlined
                  process, get faster responses, and don’t have to wait as long
                  for a hiring decision.
                </p>
                <p className="uiliuo1">
                  Automated recruiting process offer numerous benefits,
                  including time and cost savings, the ability to screen more
                  applicants, reduced errors and improved candidate
                  communication. It also allows for easy scaling of the hiring
                  process, promotes equitable hiring, simplifies compliance,
                  optimizes hiring processes, quickens offer letters and eases
                  on boarding. Automated recruiting involves streamlining the
                  hiring process by using automation to organize and manage the
                  manual and administrative tasks associated with talent
                  acquisition.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog2} alt="" />
              </div>
            </div>
            <ol>
              <div className="setup1">
                <div className="supset1">
                  <img className="set1img" src={Blog3} alt="" />
                </div>
                <div className="supset2">
                  <p className="uiliuo1">
                    <b>
                      Automated recruiting and how it can improve the overall
                      hiring process:
                    </b>
                  </p>
                  <li className="uliu">Posting jobs to job boards</li>
                  <p className="uiliuo1">
                    The actual act of posting jobs to job boards is a mundane-
                    but essential- task. Advertising open positions is the first
                    step to reaching job seekers, but writing up a job
                    description and posting it to numerous job sites is a time-
                    intensive chore.
                  </p>
                  <p className="uiliuo1">
                    An applicant tracking system streamlines the process by
                    automatically posting your job listing to relevant career
                    sites, including LinkedIn, Indeed, Glassdoor and others.
                  </p>

                  <li className="uliu">Tracking your applicants</li>
                  <p className="uiliuo1">
                    Although an abundance of qualified candidates is a good
                    "problem" to have, it can be a challenge to track and manage
                    applicants throughout the process. Recruiting software can
                    assist with candidate relationship management and improve
                    candidate engagement
                  </p>
                  <li className="uliu">Scheduling interviews</li>
                  <p className="uiliuo1">
                    The interview process is critical in identifying top
                    candidates, but interview scheduling requires a lot of back
                    and forth communication between multiple parties and can
                    quickly feel disorganized.
                  </p>
                </div>
              </div>
              <div className="setup1">
                <div className="supset2">
                  <p className="uiliuo1">
                    Offering candidates the option to self-schedule their
                    in-person, phone, or video interviews is one of many
                    recruitment automation tools that makes the hiring process
                    easier for everyone involved.
                  </p>
                  <li className="uliu">Email communications</li>
                  <p className="uiliuo1">
                    Although the hiring process is typically viewed as the
                    applicant’s opportunity to impress a potential employer, the
                    best candidates are also assessing whether or not your
                    company is the right fit for them. Prompt communication
                    throughout the process demonstrates that you respect the
                    time, interest, and effort of applicants. Automated
                    recruiting tools can schedule emails and follow-ups
                    throughout the process, from introduction to offer letter.
                  </p>
                  <li className="uliu">Approval workflows</li>
                  <p className="uiliuo1">
                    Hiring decisions should be based on finding the right person
                    for the job — not the person who approves the job
                    requisition. Algorithms can’t replace the human input and
                    perspective that facilitates finding the perfect fit.
                    However, using automated recruiting tools to implement a
                    standard approval workflow, or to create a custom one,
                    ensures that stakeholders are automatically
                  </p>
                </div>
                <div className="supset1">
                  <img className="set2img" src={Blog4} alt="" />
                </div>
              </div>

              <div className="setup1">
                <div className="supset1">
                  <img className="set1img" src={Blog5} alt="" />
                </div>
                <div className="supset2">
                  <p className="uiliuo1">
                    notified about next steps and action items throughout the
                    process, so that everyone has all necessary information and
                    is included in their part of the decision-making process.
                  </p>
                  <li className="uliu">Consider Future Growth:</li>
                  <p className="uiliuo1">
                    Hiring is not just about filling a current vacancy; it's
                    also about future-proofing your team.
                  </p>
                  <p className="uiliuo1">
                    Identifying candidates who not only meet immediate needs but
                    also exhibit the potential for growth within the
                    organization ensures a dynamic and agile workforce prepared
                    to meet evolving challenges.
                  </p>
                  <li className="uliu">Managing hiring plan</li>
                  <p className="uiliuo1">
                    Talent acquisition has many different stages. Plan the
                    hiring process, track progress, manage your budget, and keep
                    stakeholders aligned along the way by using automation
                    technology to establish approval workflows and capture
                    requisitions so that no one has to chase down approval or
                    inquire about timing or budget. A comprehensive hiring plan
                    paired with the appropriate tools makes hiring new talent a
                    seamless experience.
                  </p>
                </div>
              </div>
            </ol>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Quation9;
