import React from "react";
import NavBar from "../Navigation/NavBar";
import Contant from "./Contant";
import Footer from "../Footer/Footer";
import Services from "./IMG/Services1.png";
import Services2 from "./IMG/Services2.png";
import Services3 from "./IMG/Services3.png";
import Services4 from "./IMG/Services4.png";
import "./Permanent.css";

function PermanentStaffing() {
  return (
    <>
      <NavBar />
      {/* Permanent Staffing Solutions */}
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiutk">
          <div className="JHDf082">
            <h1 className="KJGd09762">Permanent Staffing Solutions</h1>
            <img className="GH0817" src={Services} alt="" />
          </div>
          <div className="JHDf082">
            <p className="KLH098761">
              Quarec Resources is a premier recruitment firm dedicated to
              connecting talent from around the world with exciting
              opportunities across national and international job markets. Our
              extensive reach and expertise allow us to connect skilled
              professionals with the best job opportunities in regions such as
              the Middle East, Africa, Canada, UK, Australia, and beyond.
            </p>
            <p className="KLH098761">
              With a focus on professional recruiting principles, we ensure a
              seamless and effective recruitment process that maximizes the
              potential of our diverse clientele. Our mission is to help
              individuals build a safe and secure future by connecting them with
              international job prospects that offer growth and stability. Join
              Quarec Resources on an exciting journey towards a brighter future,
              where global opportunities await talented individuals like you.
            </p>
          </div>
        </div>
      </div>
      {/* Permanent Staffing Solutions */}
      {/* Temporary Staffing */}
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <h1 className="Respohead">Temporary Staffing</h1>
            <p className="KLH0987612">
              Quarec Resources is a staffing agency that offers temporary
              staffing solutions. They excel at sourcing and vetting candidates
              for different positions, taking care of administrative tasks such
              as contracts and payroll. Their temporary workers seamlessly
              integrate into client teams and contribute to their overall
              success.
            </p>
            <p className="KLH0987612">
              Quarec Resources provides clients with scalability,
              cost-effectiveness, access to specialized skills, and the chance
              to evaluate potential permanent hires. Temporary workers benefit
              from valuable experience, exposure to diverse industries,
              networking opportunities, and potential pathways to permanent
              employment. The agency prioritizes compliance with labor laws,
              ensuring a smooth and legally compliant staffing experience.
            </p>
          </div>
          <div className="JHDf0820">
            <h1 className="KJGd0976213">Temporary Staffing</h1>
            <img className="GH08170" src={Services2} alt="" />
          </div>
        </div>
      </div>
      {/* Temporary Staffing */}
      {/* Payroll Management */}
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiutk1">
          <div className="JHDf082">
            <h1 className="KJGd09762">Payroll Management</h1>
            <img className="GH0817" src={Services3} alt="" />
          </div>
          <div className="JHDf082">
            <p className="KLH098761">
              At Quarec Resources, our payroll management ensures accurate and
              timely compensation for our temporary workers. Our dedicated team
              handles timekeeping, wage calculation, deductions, and payroll
              processing. We comply with legal requirements, providing detailed
              payslips and maintaining confidentiality. With efficient processes
              in place, we prioritize accuracy and transparency.
            </p>
            <p className="KLH098761">
              Our goal is to ensure that our temporary workers receive their
              wages on time and are supported throughout the payroll process.
              Clear communication and prompt assistance are at the forefront of
              our payroll management approach, allowing us to maintain positive
              relationships with our temporary workers and provide reliable
              services to our clients.
            </p>
          </div>
        </div>
      </div>
      {/* Payroll Management */}
      {/* Dedicated IT Resource */}
      <div className="CALIyhaap0185">
        <div className="HGD906PLoiu">
          <div className="JHDf082">
            <h1 className="Respohead">Dedicated IT Resource</h1>
            <p className="KLH0987612">
              Quarec Resources offers dedicated IT resources and teams to
              support a wide range of projects. Our skilled IT professionals are
              available to provide specialized expertise and assistance tailored
              to your specific needs. Whether you require support for software
              development, system administration, network infrastructure,
              cybersecurity, or any other IT-related projects,
            </p>
            <p className="KLH0987612">
              our dedicated resources are ready to contribute their knowledge
              and skills. We ensure that our IT teams are equipped with the
              latest technologies and tools to deliver efficient and effective
              solutions. Partner with Quarec Resources to access dedicated IT
              resources that will drive the success of your projects.
            </p>
          </div>
          <div className="JHDf0820">
            <h1 className="KJGd0976213">Dedicated IT Resource</h1>
            <img className="GH08170" src={Services4} alt="" />
          </div>
        </div>
      </div>
      {/* Dedicated IT Resource */}
      <Footer />
    </>
  );
}

export default PermanentStaffing;
