import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import Contant from "./Contant";
import Footer from "../Footer/Footer";

function Executive() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <Contant PageName="EXECUTIVE SEARCH" />
      <Footer />
    </>
  );
}

export default Executive;
