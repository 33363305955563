import React from "react";
import "./info.css";

function Info() {
  return (
    <>
      <div className="Hd0816ZAAmkl">
        <h1 className="BKS7615">"Let's build your dream team together"</h1>
        <p className="jf0111654">
          Ignite the power of exceptional talent with Our Recruitment Agency. We
          understand that finding the perfect fit for your business is the key
          to success. With our expert recruitment solutions, we'll help you
          build a dream team that drives innovation and growth. Collaborate with
          our experienced recruiters to find extraordinary talent that will
          elevate your business to new heights. Contact us today and embark on a
          transformative journey to success with Our Recruitment Agency.
        </p>
      </div>
    </>
  );
}

export default Info;
