import React, { useState } from "react";
import "./Application.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheck } from "react-icons/fa";
import axios from "axios";

function JobApplication() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [JobRole, setJobRole] = useState("");
  const [YearofExperience, setYearofExperience] = useState("");
  const [CTC, setCTC] = useState("");
  const [CurentC, setCurentC] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [JobSectors, setJobSectors] = useState("");
  const [PortfolioURL, setPortfolioURL] = useState("");

  const [NameError, setNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [JobRoleError, setJobRoleError] = useState("");
  const [YearofExperienceError, setYearofExperienceError] = useState("");
  const [CTCError, setCTCError] = useState("");
  const [CurentCError, setCurentCError] = useState("");
  const [ResumeError, setResumeError] = useState("");
  // const [JobSectorsError, setJobSectorsError] = useState("");
  const [JobSectorsError, setJobSectorsError] = useState("");
  const [PortfolioURLError, setPortfolioURLError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };
  const handleJobRoleChange = (event) => {
    setJobRole(event.target.value);
  };
  const handleYearofExperienceChange = (event) => {
    setYearofExperience(event.target.value);
  };
  const handleCTCChange = (event) => {
    setCTC(event.target.value);
  };
  const handleCurentCChange = (event) => {
    setCurentC(event.target.value);
  };

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file.type === "application/pdf") {
      setSelectedFile(file);
      setResumeError("");
    } else {
      setSelectedFile(null);
      setResumeError("Please select a PDF file.");
    }
  };

  const handleJobSectorsChange = (event) => {
    setJobSectors(event.target.value);
  };

  const handlePortfolioURLChange = (event) => {
    setPortfolioURL(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for errors
    let hasError = false;

    if (!Name.trim()) {
      setNameError("Name is required");
      hasError = true;
    } else if (!/^[a-zA-Z ]+$/.test(Name)) {
      setNameError("Name should only contain alphabetic characters and spaces");
      hasError = true;
    } else if (Name.length < 3) {
      setNameError("Name should be at least 3 characters long");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!Email.trim()) {
      setEmailError("Email is required");
      hasError = true;
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        Email
      )
    ) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!Mobile.trim()) {
      setMobileError("Mobile Number is required");
      hasError = true;
    } else if (!/^\d{10}$/.test(Mobile)) {
      setMobileError("Mobile Number should be a 10-digit number");
      hasError = true;
    } else {
      setMobileError("");
    }

    if (!JobRole) {
      setJobRoleError("Please select your Job Role");
      hasError = true;
    } else if (!/^[a-zA-Z\s]+$/.test(JobRole)) {
      setJobRoleError("Job Role can only contain letters and spaces");
      hasError = true;
    } else {
      setJobRoleError("");
    }

    if (!YearofExperience) {
      setYearofExperienceError("Please select Year of Experience");
      hasError = true;
    } else if (YearofExperience < 0 || YearofExperience > 20) {
      setYearofExperienceError("Year of Experience should be between 0 and 20");
      hasError = true;
    } else {
      setYearofExperienceError("");
    }

    if (!CTC) {
      setCTCError("Please select CTC");
      hasError = true;
    } else if (CTC < 0 || CTC > 3000000) {
      setCTCError("CTC must be between 0 and 30,00,000");
      hasError = true;
    } else {
      setCTCError("");
    }

    if (!CurentC || CurentC < 0 || CurentC > 5000000) {
      setCurentCError("Please select Expected CTC between 0 and 5000000");
      hasError = true;
    } else {
      setCurentCError("");
    }

    if (!selectedFile) {
      setResumeError("Please select a Resume file.");
      hasError = true;
    } else {
      setResumeError("");
    }

    if (!JobSectors) {
      setJobSectorsError("Please select Job Sectors");
      hasError = true;
    } else {
      setJobSectorsError("");
    }

    const urlRegex =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

    if (JobSectors === "IT" && !PortfolioURL) {
      setPortfolioURLError("Please enter Portfolio URL");
      hasError = true;
    } else if (JobSectors === "IT" && !urlRegex.test(PortfolioURL)) {
      setPortfolioURLError("Please enter a valid URL");
      hasError = true;
    } else {
      setPortfolioURLError("");
    }

    // Submit the form if there are no errors
    if (!hasError) {
      console.log("Form Data", {
        Name,
        Email,
        Mobile,
        JobRole,
        YearofExperience,
        CTC,
        CurentC,
        selectedFile,
        JobSectors,
        PortfolioURL,
      });
      // ...submit the form
      try {
        const response = await axios.post(
          // process.env.REACT_APP_API_KEY + "/job_applications.php",
          {
            // data: {
            full_name: Name,
            email: Email,
            phone_number: Mobile,
            resume_path: selectedFile,
            job_role: JobRole,
            experience: YearofExperience,
            current_ctc: CurentC,
            expected_ctc: CTC,
            job_sectors: JobSectors,
            profile_url: PortfolioURL,
            message: "Details saved Successfully",
            // },
          }
        );
        console.log(response.data);

        // Show success message to the user
        toast.success("Form submitted successfully!", {
          bodyClassName: "my-toast-body",
          progressBarStyle: { backgroundColor: "#2F7175" }, // Change the bottom line color
          icon: <FaCheck color="#2F7175" />, // Change the icon color
        });
      } catch (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        console.error(error);

        // Show error message to the user
        toast.error(
          "An error occurred while submitting the form. Please try again."
        );
      }
    }
  };
  return (
    <>
      <ToastContainer className="my-toast-container" closeButton={false} />
      <div className="HD09017">
        <h1 className="HGS071100">
          "Your dream job is just a click away , let's make it happen together."
        </h1>
        <p className="D81opq">
          "Discover Your Perfect Career Path: Explore a Diverse Range of Top Job
          Opportunities Customized to Suit Your Expertise and Skills!" Contact
          us now!
        </p>
      </div>
      <div className="Vamjhg0817">
        <form
          // onSubmit={handleSubmit}
          action="https://quarecres.mcxcontrol.com/form.php"
          method="post"
          enctype="multipart/form-data"
        >
          <div className="VGAlop">
            <label className="HFSgh09716">Full Name</label>
            <input
              className="NameFiled"
              type="text"
              placeholder="Enter Your Name"
              value={Name}
              onChange={handleNameChange}
              name="full_name"
              required
            />
            {NameError && <div className="COnFom">{NameError}</div>}
          </div>
          <div className="Vag0poqw">
            <div className="HGSDjdf">
              <label className="HFSgh09716">Email Address</label>
              <input
                className="NameFiled"
                type="email"
                placeholder="Enter Email Address"
                value={Email}
                onChange={handleEmailChange}
                name="email"
                required
              />
              {EmailError && <div className="COnFom">{EmailError}</div>}
            </div>
            <div className="HGSDjdf">
              <label className="HFSgh09716">Phone Number</label>
              <input
                className="NameFiled"
                type="number"
                placeholder="Phone Number"
                value={Mobile}
                onChange={handleMobileChange}
                name="phone_number"
                required
              />
              {MobileError && <div className="COnFom">{MobileError}</div>}
            </div>
          </div>
          <div className="Vag0poqw">
            <div className="HGSDjdf">
              <label className="HFSgh09716">Job Role</label>
              <input
                className="NameFiled"
                type="text"
                placeholder="Enter Job Role"
                value={JobRole}
                onChange={handleJobRoleChange}
                name="job_role"
                required
              />
              {JobRoleError && <div className="COnFom">{JobRoleError}</div>}
            </div>
            <div className="HGSDjdf">
              <label className="HFSgh09716">Year of Experience</label>
              <input
                className="NameFiled"
                type="number"
                placeholder="Year of Experience"
                value={YearofExperience}
                onChange={handleYearofExperienceChange}
                name="experience"
                required
              />
              {YearofExperienceError && (
                <div className="COnFom">{YearofExperienceError}</div>
              )}
            </div>
          </div>
          <div className="Vag0poqw">
            <div className="HGSDjdf">
              <label className="HFSgh09716">Current CTC</label>
              <input
                className="NameFiled"
                type="number"
                placeholder="Enter Current CTC"
                value={CTC}
                onChange={handleCTCChange}
                name="current_ctc"
                required
              />
              {CTCError && <div className="COnFom">{CTCError}</div>}
            </div>
            <div className="HGSDjdf">
              <label className="HFSgh09716">Expected CTC</label>
              <input
                className="NameFiled"
                type="number"
                placeholder="Year of Expected CTC"
                value={CurentC}
                onChange={handleCurentCChange}
                name="expected_ctc"
                required
              />
              {CurentCError && <div className="COnFom">{CurentCError}</div>}
            </div>
          </div>
          <div className="Vag0poqw">
            <div className="HGSDjdf">
              <label className="HFSgh09716">Job Sectors</label>
              <select
                className="NameFiled"
                value={JobSectors}
                onChange={handleJobSectorsChange}
                style={{ borderBlockColor: "#2F7175" }}
                name="job_sectors"
              >
                <option value="">Select Job Sectors</option>
                <option value="IT">IT</option>
                <option value="non-IT">Non IT</option>
              </select>
              {JobSectorsError && (
                <div className="COnFom">{JobSectorsError}</div>
              )}
            </div>
            {JobSectors === "IT" && (
              <div className="HGSDjdf">
                <label className="HFSgh09716">Portfolio URL</label>
                <input
                  className="NameFiled"
                  type="text"
                  value={PortfolioURL}
                  onChange={handlePortfolioURLChange}
                  placeholder="Portfolio URL"
                  name="profile_url"
                  required
                />
                {PortfolioURLError && (
                  <div className="COnFom">{PortfolioURLError}</div>
                )}
              </div>
            )}
          </div>
          <div className="Vakg09651">
            <input
              name="resume"
              accept="application/pdf"
              className="UploadInput"
              type="file"
              placeholder="Upload Resume"
              onChange={handleFileSelection}
              required
            />
            <button className="Bab08716" type="file">
              Browse
            </button>
            {ResumeError && <div className="COnFom">{ResumeError}</div>}
          </div>
          <button className="Shayiu-btn">Submit</button>
        </form>
      </div>
    </>
  );
}

export default JobApplication;
