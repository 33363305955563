import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./NewBlog.css";
import NavBar from "../../Navigation/NavBar";
import Footer from "../../Footer/Footer";
import { Icon } from "@iconify/react";
import arrowLeft from "@iconify/icons-mdi/arrow-left";
import Blog1 from "../IMG/09-2023/Blog1.svg";
import Blog2 from "../IMG/09-2023/Blog2.svg";
import Blog3 from "../IMG/09-2023/Blog3.svg";
import Blog4 from "../IMG/09-2023/Blog4.svg";

function InternalMomentum() {
  let navigate = useNavigate();
  const GoBack = () => {
    let path = `/Blog`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      <div className="subtrnthblog">
        <h1 className="texico">
          <Icon onClick={GoBack} className="iconicals" icon={arrowLeft} />
          How to Create Internal Momentum for Your Employer Brand
        </h1>
        <p className="DateF">09/09/2023 3:20 PM</p>
        <div className="responssetpup">
          <ul>
            {/* <p className="uliu">
              Crafting a compelling pitch deck is a critical component in an
              entrepreneur's quest for funding.
            </p> */}
            <p className="uiliuo">
              Employer branding is still an emerging discipline. Therefore,
              there is still a range of reactivity and sponsorship for employer
              brands (and related fields) from company to company. Year over
              year, research firms and consultancies report that between 45% to
              65% of HR leaders report that investing in external employer
              branding is a key priority in a post-pandemic workforce. Some
              organizations (like, SAP, Chipotle, Electronic Arts, Cox, and
              Kohler, to name a few) lead in innovative employer brand
              storytelling and outwardly demonstrate an ongoing investment of
              resources in supporting continual employer brand development and
              exploration.
            </p>
            <p className="uiliuo">
              This isn’t the case for everyone. Many employer brand specialists
              working inside corporate structures report that change resistance
              at executive levels can be a difficult roadblock to overcome when
              time is of the essence to differentiate your culture narrative in
              a competitive workforce.
            </p>
          </ul>
          <ul>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog1} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">
                  Common challenges employer branders report:
                </li>
                <p className="uiliuo1">
                  Duplicating effort to produce a business case or competitive
                  insight report justifying the need for employer brand budget
                  (or budget allocation) each time there is a need to explore a
                  new method or tactic. This is usually the outcome of too few
                  touchpoints with business partners to keep them updated on the
                  performance of ongoing initiatives.
                </p>
                <p className="uiliuo1">
                  Hyper-vigilance around bottom-of-the-funnel metrics and
                  discarding of awareness-level performance. This usually sounds
                  like, “We’d like to know how many high-quality candidates we
                  can expect from this talent advocacy initiative.”
                </p>
                <p className="uiliuo1">
                  Stop-start de-prioritization of employer branding initiatives
                  due to lack of clarity on business priorities and hiring
                  forecasts. Sometimes, it can feel like employer branding is
                  marked as ‘optional’ on important executive agendas and that
                  can result in long wait times for approvals.
                </p>
                <p className="uiliuo1">
                  It’s challenging to know where to focus in a tight labour
                  market. Things change daily. Employer branders often find
                  themselves in the position of having to justify budgets with
                  immediate results or the plug gets pulled.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <p className="uiliuo1">
                  Or, the individuals or teams responsible for employer branding
                  find themselves so stretched with reactive recruitment
                  requests (“We need to launch a sponsored campaign to hire
                  twenty new software engineers by next month”) that the real,
                  strategic work to define a visual identity and associated
                  strategy never takes off.
                </p>
                <li className="uliu">
                  Why is reactive recruitment a barrier to success?
                </li>
                <p className="uiliuo1">
                  Reactive recruitment assumes that there is a pool of talent in
                  any given demographic with a passion for your purpose and the
                  right skills and experience to work well at your organization.
                  That just isn’t true. There are many indicators that the
                  global labour shortage and shifts in the workforce landscape
                  mean this is the Age of the Employee.
                </p>
                <p className="uiliuo1">
                  Without a brand segmentation strategy designed specifically to
                  share your culture, your commitments to employees, and what
                  you stand for, (and, of course, employee experiences and
                  behaviours that back it up) it’s unlikely you will succeed in
                  creating and sustaining pipelines of top talent.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog2} alt="" />
              </div>
            </div>
            <div className="setup1">
              <div className="supset1">
                <img className="set1img" src={Blog3} alt="" />
              </div>
              <div className="supset2">
                <li className="uliu">
                  3 Concepts to Create Stronger Collaboration and Generate
                  Momentum
                </li>
                <p className="uiliuo1">
                  Assemble a group of stakeholders with influence and keep them
                  informed. Call it a steering committee, a cross-functional
                  team… make sure Brand, Communications, and ED&I
                  representatives are in the room. Use a regular quarterly
                  cadence to report on employer brand learnings and progress and
                  use this group as an advisory council. For example, when it
                  comes time to refresh career site content, this team can help
                  guide the creative direction of the site. Bringing influential
                  leaders together as collaborators on key initiatives makes it
                  easier to secure important green lights down the road.
                </p>
                <p className="uiliuo1">
                  When it comes to Business Leaders, let goals be your guide.
                  Often, an area of the business will fund an employer brand
                  initiative (like, a recruitment marketing campaign). These are
                  important opportunities to secure proof-of-concept data that
                  you can leverage in future funding requests and business
                  cases. For example, you have the opportunity to run a $100,000
                  digital media campaign to attract data scientists. Make sure
                  you set clear, measurable goals with the line of business and
                  recruitment leaders and create a continual communication and
                  measurement strategy. Over the course of the campaign, monitor
                  and report on your goals, tweaking the strategy (with business
                  input) as needed.
                </p>
              </div>
            </div>
            <div className="setup1">
              <div className="supset2">
                <p className="uiliuo1">
                  At the end of the campaign, even if you didn’t reach your
                  goals, you now have a wealth of data to inform and strengthen
                  your next campaign.
                </p>

                <p className="uiliuo1">
                  Every initiative is a “backdoor pilot”. Every campaign, no
                  matter how small and underfunded, is an opportunity to
                  audition new ideas, content, concepts, creative, messaging,
                  and brand narratives to your talent. The data you collect from
                  these initiatives can inform your broader talent strategy,
                  strengthening your approach and letting you know what talent
                  needs. Not having a team, an agency, or a budget is the plight
                  of the employer brand strategist, especially when so often
                  “build the organization’s employer brand” is the only tangible
                  bullet point on the job description. But, ever-evolving
                  problems and challenges call for non-linear solutions. An
                  employer brander’s best tools are patience, creativity, and
                  resourcefulness (in that order, I think). Leveraging these to
                  cultivate strong relationships will open the door for the
                  organization to embrace employer branding.
                </p>

                <p className="uiliuo1">
                  It takes a lot of courage to champion a new concept,
                  especially when met with resistance and skepticism. A great
                  strategy is to understand the root cause of the resistance
                  (generally, fear or unfamiliarity) and find ways to
                  collaborate with stakeholders and business partners to assuage
                  their concerns using the principles and tactics you know will
                  deliver value.
                </p>
              </div>
              <div className="supset1">
                <img className="set2img" src={Blog4} alt="" />
              </div>
            </div>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InternalMomentum;
