import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./FullBlog.css";
import NavBar from "../Navigation/NavBar";
import Footer from "../Footer/Footer";
import { Icon } from "@iconify/react";
import arrowLeft from "@iconify/icons-mdi/arrow-left";

function FullBlog() {
  let navigate = useNavigate();
  const GoBack = () => {
    let path = `/Blog`;
    navigate(path);
  };

  // API Integaration
  const { state } = useLocation();
  const data = state;

  console.log(data);

  const [innerdata] = useState(data.props);
  console.log(innerdata);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavBar />
      <div>
        <div className="HDG971">
          <Icon onClick={GoBack} className="HDf0816" icon={arrowLeft} />
          <h1 className="mZajkg">{innerdata.title}</h1>
        </div>
        <div className="Vajg0826">
          <img
            className="JKDg9072"
            src={process.env.REACT_APP_API_KEY + `${innerdata.profile_image}`}
            alt="Not Found"
          />
          <h1 className="KHa082">{innerdata.title}</h1>
          <p className="Baj0978">
            {innerdata.updated_at.slice(8, 10)}-
            {innerdata.updated_at.slice(5, 7)}-
            {innerdata.updated_at.slice(0, 4)}
            &nbsp;
            {innerdata.created_at.slice(11, 16)}
          </p>
          <p
            className="Baj8612t"
            dangerouslySetInnerHTML={{
              __html: innerdata.description,
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FullBlog;
