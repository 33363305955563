import React from "react";
import "./OurProcess.css";
import process from "./IMG/Process.svg";
import Spro from "./IMG/Spro.svg";

function OurProcess() {
  return (
    <>
      <h1 className="DCxjgf91">OUR PROCESS</h1>
      <div className="CVjfZ">
        <img className="FV0722e" src={process} alt="" />
        <img className="BLOklk" src={Spro} alt="" />
      </div>
    </>
  );
}

export default OurProcess;
