import React, { useEffect } from "react";
import NavBar from "../Navigation/NavBar";
import Footer from "../Footer/Footer";
import Services2 from "./IMG/Services2.png";
import Services3 from "./IMG/Services3.png";
import Services4 from "./IMG/Services4.png";
import Services5 from "./IMG/Services5.png";
import Services6 from "./IMG/Services6.png";
import Services7 from "./IMG/Services7.png";
import Services8 from "./IMG/Services8.png";
import Services9 from "./IMG/Services9.png";
import Services10 from "./IMG/Services10.png";
import "./Permanent.css";
// import WorldMap from "./IMG/WorldMap.png"
import WorldMap from "./IMG/WorldMap.svg";

function MainServices() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavBar />
      {/* Permanent Staffing Solutions */}
      <div id="permanent-staffing" className="SetService972">
        <div className="FG722">
          <div className="ServiveImgDX">
            <h1 className="SerHed">Permanent Staffing Solutions</h1>
            <img className="SerImg" src={WorldMap} alt="" />
          </div>
          <div className="SerrInnner">
            <div className="TextContainer">
              <p className="TextInner">
                Quarec Resources is a premier recruitment firm dedicated to
                connecting talent from around the world with exciting
                opportunities across national and international job markets. Our
                extensive reach and expertise allow us to connect skilled
                professionals with the best job opportunities in regions such as
                the Middle East, Africa, Canada, UK, Australia, and beyond.
              </p>
              <p className="TextInner">
                With a focus on professional recruiting principles, we ensure a
                seamless and effective recruitment process that maximizes the
                potential of our diverse clientele. Our mission is to help
                individuals build a safe and secure future by connecting them
                with international job prospects that offer growth and
                stability. Join Quarec Resources on an exciting journey towards
                a brighter future, where global opportunities await talented
                individuals like you.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Permanent Staffing Solutions */}
      {/* Temporary Staffing */}
      <div id="temporary-staffing" className="SetService972">
        <div className="FG722">
          <h1 className="SetMediaFor">Temporary Staffing</h1>
          <div className="SerrInnner">
            <div className="TextContainerleft">
              <p className="TextInnerleft">
                Quarec Resources is a staffing agency that offers temporary
                staffing solutions. They excel at sourcing and vetting
                candidates for different positions, taking care of
                administrative tasks such as contracts and payroll. Their
                temporary workers seamlessly integrate into client teams and
                contribute to their overall success.
              </p>
              <p className="TextInnerleft">
                Quarec Resources provides clients with scalability,
                cost-effectiveness, access to specialized skills, and the chance
                to evaluate potential permanent hires. Temporary workers benefit
                from valuable experience, exposure to diverse industries,
                networking opportunities, and potential pathways to permanent
                employment. The agency prioritizes compliance with labor laws,
                ensuring a smooth and legally compliant staffing experience.
              </p>
            </div>
          </div>
          <div className="ServiveImgDX">
            <h1 className="SerHedright">Temporary Staffing</h1>
            <img className="SerImgright" src={Services2} alt="" />
          </div>
        </div>
      </div>
      {/* Temporary Staffing */}
      {/* Payroll Management */}
      <div id="payroll-management" className="SetService972">
        <div className="FG722">
          <div className="ServiveImgDX">
            <h1 className="SerHed">Payroll Management</h1>
            <img className="SerImg" src={Services3} alt="" />
          </div>
          <div className="SerrInnner">
            <div className="TextContainer">
              <p className="TextInner">
                At Quarec Resources, our payroll management ensures accurate and
                timely compensation for our temporary workers. Our dedicated
                team handles timekeeping, wage calculation, deductions, and
                payroll processing. We comply with legal requirements, providing
                detailed payslips and maintaining confidentiality. With
                efficient processes in place, we prioritize accuracy and
                transparency.
              </p>
              <p className="TextInner">
                Our goal is to ensure that our temporary workers receive their
                wages on time and are supported throughout the payroll process.
                Clear communication and prompt assistance are at the forefront
                of our payroll management approach, allowing us to maintain
                positive relationships with our temporary workers and provide
                reliable services to our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Payroll Management */}
      {/* Dedicated IT Resource */}
      <div id="dedicated-it-resource" className="SetService972">
        <div className="FG722">
          <h1 className="SetMediaFor">Dedicated IT Resource</h1>
          <div className="SerrInnner">
            <div className="TextContainerleft">
              <p className="TextInnerleft">
                Quarec Resources is a staffing agency that offers temporary
                staffing solutions. They excel at sourcing and vetting
                candidates for different positions, taking care of
                administrative tasks such as contracts and payroll. Their
                temporary workers seamlessly integrate into client teams and
                contribute to their overall success.
              </p>
              <p className="TextInnerleft">
                Quarec Resources provides clients with scalability,
                cost-effectiveness, access to specialized skills, and the chance
                to evaluate potential permanent hires. Temporary workers benefit
                from valuable experience, exposure to diverse industries,
                networking opportunities, and potential pathways to permanent
                employment. The agency prioritizes compliance with labor laws,
                ensuring a smooth and legally compliant staffing experience.
              </p>
            </div>
          </div>
          <div className="ServiveImgDX">
            <h1 className="SerHedright">Dedicated IT Resource</h1>
            <img className="SerImgright" src={Services4} alt="" />
          </div>
        </div>
      </div>
      {/* Dedicated IT Resource */}
      {/* International Recruitment */}
      <div id="international-recruitment" className="SetService972">
        <div className="FG722">
          <div className="ServiveImgDX">
            <h1 className="SerHed">International Recruitment</h1>
            <img className="SerImg" src={Services5} alt="" />
          </div>
          <div className="SerrInnner">
            <div className="TextContainer">
              <p className="TextInner">
                At Quarec Resources, we specialize in international recruitment,
                assisting companies in sourcing and hiring talented
                professionals from around the world. Here's an overview of our
                international recruitment process:
              </p>
              <p className="TextInner">
                Client Consultation: We collaborate closely with our clients to
                understand their specific staffing needs, including the desired
                skills, qualifications, and experience required for the
                positions
              </p>
              <p className="TextInner">
                Talent Sourcing: Our global network and extensive resources
                enable us to source candidates from various countries and
                regions. We employ various strategies such as targeted
                advertising, networking, and leveraging online platforms to
                attract top international talent.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="SetService972">
        <div className="FG722">
          <div className="SerrInnner">
            <div className="TextContainerleft">
              <p className="TextInnerleft">
                Candidate Screening: We conduct rigorous screening processes,
                including resume review, interviews, and assessments, to
                evaluate the candidates' qualifications, language proficiency,
                cultural fit, and suitability for the roles they are being
                considered for.
              </p>
              <p className="TextInnerleft">
                Visa and Immigration Support: We assist candidates with the visa
                and immigration processes, ensuring compliance with the host
                country's immigration laws. We provide guidance and support to
                navigate the complexities of work permits, visa applications,
                and any other necessary documentation.
              </p>
              <p className="TextInnerleft">
                Cultural Orientation and Support: We provide cultural
                orientation to help candidates adapt to their new work
                environment and local customs. This includes providing
                information on local laws, customs, and workplace etiquette,
                helping them seamlessly integrate into the client's
                organization.
              </p>
            </div>
          </div>
          <div className="ServiveImgDX">
            {/* <h1 className="SerHedright">Dedicated IT Resource</h1> */}
            <img className="SerImgright" src={Services6} alt="" />
          </div>
        </div>
      </div>
      <div className="SetService972">
        <div className="FG722">
          <div className="ServiveImgDX">
            {/* <h1 className="SerHed">International Recruitment</h1> */}
            <img className="SerImg" src={Services7} alt="" />
          </div>
          <div className="SerrInnner">
            <div className="TextContainer">
              <p className="TextInner">
                Travel and Relocation Logistics: We handle travel arrangements,
                such as flight bookings and accommodation, to facilitate the
                candidate's relocation. We ensure a smooth transition by
                assisting with logistics and addressing any concerns or queries
                related to the relocation process.
              </p>
              <p className="TextInner">
                On boarding and Integration: We work closely with our clients to
                facilitate a comprehensive onboarding process for international
                hires. This includes familiarizing them with company policies,
                procedures, and team dynamics to ensure a successful integration
                into the client's organization.
              </p>
              <p className="TextInner">
                Ongoing Support: We maintain regular communication with both the
                client and the candidate to ensure a positive experience and
                address any issues or concerns that may arise during their
                employment. We provide ongoing support to foster a productive
                and satisfying working relationship
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* International Recruitment */}
      {/* Executive Search */}
      <div id="executive-search" className="SetService972">
        <div className="SetService972">
          <div className="FG722">
            <h1 className="SetMediaFor">Executive Search:</h1>
            <div className="SerrInnner">
              <div className="TextContainerleft">
                <p className="TextInnerleft">
                  Quarec Resources offers dedicated IT resources and teams to
                  support a wide range of projects. Our skilled IT professionals
                  are available to provide specialized expertise and assistance
                  tailored to your specific needs. Whether you require support
                  for software development, system administration, network
                  infrastructure, cybersecurity, or any other IT-related
                  projects,
                </p>
                <p className="TextInnerleft">
                  our dedicated resources are ready to contribute their
                  knowledge and skills. We ensure that our IT teams are equipped
                  with the latest technologies and tools to deliver efficient
                  and effective solutions. Partner with Quarec Resources to
                  access dedicated IT resources that will drive the success of
                  your projects.
                </p>
              </div>
            </div>
            <div className="ServiveImgDX">
              <h1 className="SerHedright">Executive Search:</h1>
              <img className="SerImgright" src={Services8} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="SetService972">
        <div className="FG722">
          <div className="ServiveImgDX">
            {/* <h1 className="SerHed">International Recruitment</h1> */}
            <img className="SerImg" src={Services9} alt="" />
          </div>
          <div className="SerrInnner">
            <div className="TextContainer">
              <p className="TextInner">
                But it doesn't stop there. We provide personalized support
                throughout the entire journey, from candidate assessment to
                negotiation and onboarding. We ensure a seamless transition,
                allowing your newly appointed executive to hit the ground
                running and make an immediate impact.
              </p>
              <p className="TextInner">
                We believe that exceptional leadership is the key to unlocking
                unparalleled success. Trust us to uncover the extraordinary
                leaders who will guide your organization towards a future of
                innovation, growth, and extraordinary achievements. Experience
                the Quarec difference and embark on a transformative journey
                with our executive search services.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Executive Search */}
      {/* Bulk Hiring */}
      <div id="bulk-hiring" className="SetService972">
        <div className="SetService972">
          <div className="FG7220">
            <h1 className="SetMediaFor">Bulk Hiring</h1>
            <div className="SerrInnner">
              <div className="TextContainerleft">
                <p className="TextInnerleft">
                  Quarec Resources excels in efficient bulk hiring, providing
                  streamlined solutions for large-scale workforce needs. With an
                  extensive talent pool, we quickly identify qualified
                  candidates. Our scalable strategies, thorough screening, and
                  timely turnaround ensure quality hires.
                </p>
                <p className="TextInnerleft">
                  We adapt to your requirements, prioritizing cultural fit. We
                  provide comprehensive on boarding support and dedicated
                  account management. Trust us to handle your bulk hiring with
                  speed, efficiency, and a focus on quality. Experience seamless
                  and successful bulk recruitment with Quarec Resources.
                </p>
              </div>
            </div>
            <div className="ServiveImgDX">
              <h1 className="SerHedright">Bulk Hiring</h1>
              <img className="SerImgright" src={Services10} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* Bulk Hiring */}
      <Footer />
    </>
  );
}

export default MainServices;
