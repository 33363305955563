import React from "react";
import "./AboutContant.css";
import Amain from "./IMG/Amain.svg";
import poly1 from "./IMG/Polygon 1.svg";
import poly2 from "./IMG/Polygon 2.svg";
import poly3 from "./IMG/Polygon 3.svg";

function AboutContant() {
  return (
    <>
      <div className="GHDf0187">
        <div className="Vajf0971">
          <img className="khFS9861" src={Amain} alt="" />
        </div>
        <div className="DG9716">
          <p className="JF08167">Who We Are</p>
          <p className="JGs07116">
            Welcome to Quarec Resources Pvt. Ltd., your trusted partner in HR
            consultancy and placement
            <br className="BRtAg" />
            services for national and international clients. We bridge the gap
            between job seekers
            <br className="BRtAg" />
            and organizations worldwide, driving growth and success on a global
            scale.
            {/* <br className="BRtAg" />
            connection that fuels growth and success on a global scale. */}
          </p>
          <p className="JGs07116">
            As a dynamic and forward-thinking recruitment consultancy firm, we
            are committed to
            <br className="BRtAg" />
            delivering exceptional outcomes and exceeding the expectations of
            our international clientele.
            <br className="BRtAg" />
            With our extensive expertise, we supply top-tier manpower across
            diverse industries and
            <br className="BRtAg" />
            verticals, catering to the multifaceted manpower requirements of
            esteemed organizations.
          </p>
          <p className="JGs07116">
            Our comprehensive solutions are tailored to your unique needs,
            ensuring a seamless and efficient
            <br className="BRtAg" />
            recruitment experience that yields remarkable results. At Quarec
            Resources Pvt. Ltd. ,
            <br className="BRtAg" />
            we are fueled by a relentless pursuit of excellence understanding of
            the transformative
            <br className="BRtAg" />
            and a deep power of exceptional talent placement.
          </p>
          {/* <p className="JGs07116">
            We understand that our triumph hinges on our ability to deliver
            positive, tailored, and easily
            <br className="BRtAg" />
            accessible professional services. Our commitment to you, our
            international clients, is unwavering.
            <br className="BRtAg" />
            We take immense pride in offering comprehensive solutions that
            perfectly align with your unique
            <br className="BRtAg" />
            needs. By providing a wide range of services, we aim to ensure a
            seamless and efficient recruitment
            <br className="BRtAg" />
            experience that yields remarkable results.
          </p> */}
        </div>
      </div>
      {/* <div className="DKH920p1qaz">
        <h2 className="KGD0927">
          Welcome to Quarec Resources Pvt. Ltd., where exceptional human
          resources solutions meet the global stage. Together, we will conquer
          new frontiers of success.
        </h2>
        <p className="TSte902">
          At Quarec Resources Pvt. Ltd., we are fueled by a relentless pursuit
          of excellence and an acute awareness of the profound impact that
          exceptional talent placement can have on organizations worldwide. We
          are thrilled to embark on this international journey with you, and we
          eagerly anticipate forging a lasting partnership that propels your
          organization's growth and success to new heights.
        </p>
      </div> */}
      <div className="JD0178">
        <img className="Vafs0917" src={poly1} alt="" />
        <img className="Vafs0917" src={poly2} alt="" />
        <img className="Vafs0917" src={poly3} alt="" />
      </div>
    </>
  );
}

export default AboutContant;
