import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Blog.css";
import Blog1 from "./IMG/M1.png";
import Blog2 from "./IMG/Blog2.svg";
import Blog3 from "./IMG/Blog3.svg";
import Blog4 from "./IMG/Blog4.svg";
import Blog5 from "./IMG/Blog5.svg";
import Blog6 from "./IMG/Blog6.svg";
import Blog7 from "./IMG/blog1.png";
import Blog8 from "./IMG/E2.png";
import blog9 from "./IMG/new/A2.png";
import blog10 from "./IMG/new/b1.png";
import Proven1 from "./IMG/23-08-2023/Proven1.svg";
import Benifit from "./IMG/09-2023/Blog1.svg";
import Changed from "./IMG/Chaged.svg";
import Firstday from "./IMG/FirstDay.png";
import Ad1 from "../Blog/IMG/ad1.png";
const blogsPerPage = 6;
const totalBlogs = 12; // Total number of static blogs

const blogData = [
  {
    img: Firstday,
    title:
      "MASTERING THE ART OF BUILDING CONNECTIONS IN YOUR FIRST 90 DAYS AT A NEW JOB ",
    content:
      "While you navigate your responsibilities and adapt to a new work environment, one of the most crucial aspects of your early days is building meaningful connections with your colleagues.",
    route: "/Firstday",
  },
  // First Blog
  {
    img: Ad1,
    title: "The Perfect Combo of Recruitment Marketing and Job Boards",
    content:
      "In the dynamic world of recruitment, Return on Investment (ROI) has emerged as a pivotal metric. Every dollar spent in the hiring process should ideally lead to a quantifiable return, whether in the form of a top-tier hire, reduced time-to-fill, or enhanced employer branding. In an era where data-driven decisions reign supreme, understanding and optimizing ROI is paramount for any forward-thinking recruitment strategy. This article delves into the synergy between recruitment marketing and job boards, and how they can be harnessed to maximize ROI.",
    route: "/RecruitmentMarketing",
  },
  {
    img: Changed,
    title:
      "The way we work has changed – now it's time to change the way we hire",
    content:
      "Too much talent is slipping through the net, says Tom Cornell, because recruitment techniques are not fit for 2023. ",
    route: "/Changed",
  },
  {
    img: Benifit,
    title: "How to Create Internal Momentum for Your Employer Brand",
    content:
      "Employer branding is still an emerging discipline. Therefore, there is still a range of reactivity and sponsorship for employer brands (and related fields) from company to company. Year over year, research firms and consultancies report that between 45% to 65% of HR leaders report that investing in external employer branding is a key priority in a post-pandemic workforce. ",
    route: "/Internal-Momentum",
  },
  {
    img: Proven1,
    title: "10 Proven Ways to Improve Your Hiring Process",
    content:
      "The Hiring Process refers to the steps companies take to find and hire qualified candidates for an open position. While hiring processes may differ depending on a company's size, goals, and hiring needs, most follow similar processes to find the best candidates for their open job.",
    route: "/Proven",
  },
  {
    img: blog10,
    title: "13 Surprising Employee Onboarding Statistics in 2023",
    content:
      "Onboarding is a key component of new hire induction and orientation. Just as agencies often use recruitment software during the hiring process, organizations also need onboarding processes for new staff. Today, more and more organizations are channeling their efforts into building structured onboarding experiences for their employees.",
    route: "/Surprising25",
  },
  // Second Blog
  {
    img: blog9,
    title: "Uses of Recruiting Automation in Your Hiring Process",
    content:
      "Automation Technology is transforming recruiting. 75% of recruiters say technology will play a larger role in their hiring process this year- and we can expect that 100% recruitment departments will rely on automation to streamline hiring processes sooner rather than later.",
    route: "/Automation",
  },
  // Third Blog
  {
    img: Blog8,
    title:
      "Elevating Human Connections Generative AI Empowers Recruiters to Prioritize the Heart of Hiring",
    content:
      "The rapid advancement of Generative AI technology is poised to usher in a transformative shift within the intricate landscape of recruiting.",
    route: "/Generative-AI",
  },
  // Fourth Blog
  {
    img: Blog7,
    title: "7 Top Pitch Deck Mistakes Startups Always Make",
    content:
      "Crafting a compelling pitch deck is a critical component in an entrepreneur's quest for funding.",
    route: "/QuationSeven",
  },
  // Five Blog
  {
    img: Blog1,
    title: "Mastering the Art of Hiring: Essential Tips and Best Practices",
    content:
      "Hiring the right talent is crucial for the success of any organization. However, finding the perfect fit can be a challenging and time-consuming process.",
    route: "/QuestionOne",
  },
  // six Blog
  {
    img: Blog2,
    title: "Amazing Tips for Better Job Hunting",
    content:
      "Unless you are one of the lucky few who works in a high-demand career, finding a new job can be a challenging and frustrating experience.",
    route: "/QuationTwo",
  },
  // seven Blog
  // {
  //   img: Blog3,
  //   title: "How can you use recruiting automation in your hiring process?",
  //   content:
  //     "Recruiting automation can greatly enhance efficiency and effectiveness in the hiring process. Here are some ways you can utilize recruiting automation:",
  //   route: "/Quationthere",
  // },
  // eight Blog
  {
    img: Blog4,
    title: "9 Proven ways to Improve Hiring Process",
    content:
      "Improving the hiring process is crucial for attracting top talent and making successful hires. Here are nine proven ways to enhance your hiring process in 2023:",
    route: "/QuationSix",
  },
  // nine Blog
  // {
  //   img: Blog5,
  //   title: "Employee Onboarding and Retention Rates in Recruitment Industry",
  //   content:
  //     "Employee onboarding and retention rates play a crucial role in the recruitment industry, as they directly impact the success",
  //   route: "/QuationFive",
  // },
  // ten Blog
  {
    img: Blog6,
    title: "How do the world’s top brands position themselves to employees?",
    content:
      "The world's top brands understand the importance of positioning themselves effectively to attract and retain employees.",
    route: "/QuationSeven",
  },
];

function BlogCards() {
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const currentBlogs = blogData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Scroll to top when currentPage changes
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage]);

  const navigate = useNavigate();
  return (
    <>
      <h1 className="JHFD976410PZ">Blogs</h1>
      <div className="Vaj0825">
        <div className="FoeMediaRespo">
          {currentBlogs.map((blog, index) => (
            <div className="jgd986" key={index}>
              <img className="Vjak081" src={blog.img} alt="Not Found" />
              <h2 className="Bak081">{blog.title}</h2>
              <p className="vjfs">{blog.content}</p>
              <button onClick={() => navigate(blog.route)} className="Blog-btn">
                Read More
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Pagination buttons */}
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(totalBlogs / blogsPerPage) },
          (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </>
  );
}

export default BlogCards;
