import React from "react";
import "./Choose.css";
import NumberCounter from "./NumberCounter";

function WhyChoose() {
  return (
    <>
      <h1 className="FD95">WHY CHOOSE US ?</h1>
      <div className="Vhjsf08676">
        <p className="Bcf90616">Expert</p>
        <div className="VChgd07613">
          <div>
            <NumberCounter stopAt={67} />
            <p className="VCf09710 ">
              Employers <br />
              Contracts
            </p>
          </div>
          <div>
            <NumberCounter stopAt={999} />
            <p className="VCf09710 ">
              Candidate
              <br /> Placed
            </p>
          </div>
          <div>
            <NumberCounter stopAt={18} />
            <p className="VCf09710 ">
              Industries <br />
              served
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChoose;
