import React from "react";
import "./Industrie.css";
import indus from "./IMG/Indus.svg";
import Small from "./IMG/SM.svg";

function Industrie() {
  return (
    <>
      <h1 className="JG9618">INDUSTRIES WE SERVE</h1>
      <div className="Vajhsf962">
        <img className="Ffg963854tr" src={indus} alt="" />
        <img className="Small-Img" src={Small} alt="" />
      </div>
    </>
  );
}

export default Industrie;
