import React from "react";
import "./Clients.css";
import MainIMG from "./IMG/MainIMG.svg";
// Responsive Imgs
import Client from "./IMG/Client.svg";
import Middle from "./IMG/Mid.svg";
import Cendidate from "./IMG/Candidate.svg";

function ClientsAndCandidates() {
  return (
    <>
      <div>
        <h1 className="ClientsData">What’s in it for you ?</h1>
        <p className="Clientsubdat">
          Partnering with Quarec Resources offers numerous benefits for both
          clients and candidates, as we are dedicated to delivering exceptional
          recruitment solutions. Here’s how we add value to their job search or
          recruitment process
        </p>
        <div className="Benefits">
          <h1 className="teglines">Benefits for Clients</h1>
          <h1 className="teglines">Benefits for Candidates</h1>
        </div>
        <div className="Clienbeener">
          <img className="CAndCMain" src={MainIMG} alt="" />
        </div>

        {/* For Responsive Started At 800px */}
        <div className="ResponsiveCAC">
          <h1 className="Respoteag">Benefits for Clients</h1>
          <img className="RespoClient" src={Client} alt="" />
          <img className="RespoClient" src={Middle} alt="" />
          <h1 className="Respoteag">Benefits for Candidates</h1>
          <img className="RespoClient" src={Cendidate} alt="" />
        </div>
      </div>
    </>
  );
}

export default ClientsAndCandidates;
