// import React from "react";
// import "./testimonials.css";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
// import { Autoplay, Pagination } from "swiper/modules";

// import Testi1 from "./IMG/Testi1.png";
// import Testi2 from "./IMG/Testi2.png";
// import Testi3 from "./IMG/Testi3.png";

// function Testimonials() {
//   return (
//     <div>
//       <h1 className="DHgd09727">
//         Success Stories in Their Own Words <br />
//         Discover the Thrilled Voices of Our Clients!
//       </h1>
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <Swiper
//           modules={[Autoplay, Pagination]}
//           spaceBetween={50}
//           slidesPerView={1}
//           // autoplay={{ delay: 3000 }}
//           pagination={{ clickable: true }}
//           loop={true}
//           style={{ display: "flex", justifyContent: "center" }}
//         >
//           <SwiperSlide className="Vnjf02346">
//             {/* <div className="jsg0972876">
//               <img className="SLiDeR1" src={Testi1} alt="Testimonial 1" />
//             </div>
//             <div className="JDGdfg">
//               <p className="FDujfgy96234">
//                 "Quarec Resources is a game-changer! Their team's dedication,
//                 professionalism, and expertise delivered the perfect candidates
//                 for our critical roles. They understood our needs, found top
//                 talent, and seamlessly integrated them into our team.
//                 <br />
//                 <span className="Fgopdf7109ZZ">
//                   - Manager at Manufacturing Company in Tanzania
//                 </span>
//               </p>
//             </div> */}
//           </SwiperSlide>
//           <SwiperSlide className="Vnjf02346">
//             {/* <div className="jsg0972876">
//               <img className="SLiDeR2" src={Testi2} alt="Testimonial 2" />
//             </div>
//             <div className="JDGdfg">
//               <p className="FDujfgy96234">
//                 "If you're looking for a recruitment partner that goes above and
//                 beyond to understand your needs and deliver outstanding
//                 candidates, look no further than Quarec Resources. They have
//                 transformed our hiring process and become an invaluable asset to
//                 our company's growth. Highly recommended!"
//                 <br />
//                 <span className="Fgopdf7109ZZ">
//                   - Director at FMCG Company in Zambia
//                 </span>
//               </p>
//             </div> */}
//           </SwiperSlide>
//           <SwiperSlide className="Vnjf02346">
//             {/* <div className="jsg0972876">
//               <img className="SLiDeR3" src={Testi3} alt="Testimonial 3" />
//             </div>
//             <div className="JDGdfg">
//               <p className="FDujfgy96234">
//                 "Quarec Resources surpassed our expectations! They brought us
//                 exceptional talent that perfectly matched our requirements.
//                 Their personalized approach and seamless process made hiring a
//                 breeze. We're thrilled with our new team members and grateful
//                 for Quarec's invaluable support."
//                 <br />
//                 <span className="Fgopdf7109ZZ">
//                   - CFO at Automotive Company in Gujarat
//                 </span>
//               </p>
//             </div> */}
//           </SwiperSlide>
//         </Swiper>
//       </div>
//     </div>
//   );
// }

// export default Testimonials;

import React from "react";
import "./testimonials.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import Testi1 from "./IMG/Testi5.png";
import Testi2 from "./IMG/Testi2.png";
import Testi3 from "./IMG/Testi3.png";

function Testimonials() {
  return (
    <div>
      <h1 className="Testimonials-head">
        Success Stories in Their Own Words <br />
        Discover the Thrilled Voices of Our Clients!
      </h1>

      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        loop={true}
        className="monial-main"
        style={{ display: "flex", justifyContent: "center", width: '100%' }}
      >
        <SwiperSlide className="monial-client" style={{ display: "flex", justifyContent: "center" }} >
          <div className="Monial-alignment">
            <div className="Img-Contaner">
              <img className="Img_Monials" src={Testi1} alt="Testimonial 3" />
            </div>
            <div className="Monial-Write">
              <p className="write-ws">
                Quarec Resources is a game-changer! Their team's dedication,
                professionalism, and expertise delivered the perfect candidates
                for our critical roles. They understood our needs, found top
                talent, and seamlessly integrated them into our team.
                <br />
                <span className="Client-Info">
                  - Manager at Manufacturing Company in Tanzania
                </span>
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="monial-client" style={{ display: "flex", justifyContent: "center" }} >
          <div className="Monial-alignment">
            <div className="Img-Contaner">
              <img className="Img_Monials" src={Testi2} alt="Testimonial 3" />
            </div>
            <div className="Monial-Write">
              <p className="write-ws">
                "If you're looking for a recruitment partner that goes above and
                beyond to understand your needs and deliver outstanding
                candidates, look no further than Quarec Resources. They have
                transformed our hiring process and become an invaluable asset to
                our company's growth. Highly recommended!"
                <br />
                <span className="Client-Info">
                  - Director at FMCG Company in Zambia
                </span>
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="monial-client" style={{ display: "flex", justifyContent: "center" }} >
          <div className="Monial-alignment">
            <div className="Img-Contaner">
              <img className="Img_Monials" src={Testi3} alt="Testimonial 3" />
            </div>
            <div className="Monial-Write">
              <p className="write-ws">
                "Quarec Resources surpassed our expectations! They brought us
                exceptional talent that perfectly matched our requirements.
                Their personalized approach and seamless process made hiring a
                breeze. We're thrilled with our new team members and grateful
                for Quarec's invaluable support."
                <br />
                <span className="Client-Info">
                  - CFO at Automotive Company in Gujarat
                </span>
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Testimonials;