import React from "react";
import "./Benner.css";
// import Vactor from "./IMG/Benner.png";
import Vactor from "./IMG/Newban.png";
import Bottom from "./IMG/Bottom.png";
import { Icon } from "@iconify/react";
import directionRotaryStraight from "@iconify/icons-carbon/direction-rotary-straight";

function Benner() {
  return (
    <>
      <div className="VaCa091">
        <div>
          <p className="Gaj018">
            Recruitment Experts <br />
            That Care.
          </p>
          <div className="Bahk0p1">
            <img className="Viamag" src={Vactor} alt="" />
          </div>
          <p className="Vahk011ZZZ">
            Tailored solutions for startups and large organizations, unleashing
            unrivaled hiring excellence. Invest in the future of talent with us.
          </p>
          <div className="Vaop2">
            <Icon icon={directionRotaryStraight} rotate={2} />
          </div>
        </div>
        <div className="Bhaakjg">
          <img className="Vajhwo" src={Bottom} alt="" />
        </div>
      </div>
      <div className="Balq019">
        <p className="Valuj016ZZ">Understanding your HR needs Better</p>
        <p className="Vakjfd132">
          Revolutionizing Recruitment for Unstoppable Success. Our tailored
          solutions are designed to ignite the potential of startups and large
          organizations, supercharging your hiring process with unrivaled
          effectiveness and efficiency. Invest in the power of excellence and
          let us be your guiding force towards a thriving future where talent
          knows no limits.
        </p>
      </div>
    </>
  );
}

export default Benner;
