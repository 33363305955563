import React, { useState } from "react";
import "./GetInTouch.css";
import { Icon } from "@iconify/react";
import mapMarkerRadius from "@iconify/icons-mdi/map-marker-radius";
import phoneCall from "@iconify/icons-bxs/phone-call";
import mailRounded from "@iconify/icons-material-symbols/mail-rounded";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheck } from "react-icons/fa";
import axios from "axios";

function GetInTouch() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");

  const [NameError, setNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [SubjectError, setSubjectError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const handleMessagechange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for errors
    let hasError = false;

    if (!Name.trim()) {
      setNameError("Name is required");
      hasError = true;
    } else if (!/^[a-zA-Z ]+$/.test(Name)) {
      setNameError("Name should only contain alphabetic characters and spaces");
      hasError = true;
    } else if (Name.length < 3) {
      setNameError("Name should be at least 3 characters long");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!Email.trim()) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!Subject.trim()) {
      setSubjectError("Subject is required");
      hasError = true;
    } else if (!/^[a-zA-Z ]+$/.test(Subject)) {
      setSubjectError("Subject should only contain alphabetic characters");
      hasError = true;
    } else if (Subject.length < 6) {
      setSubjectError("Subject should be at least 6 characters long");
      hasError = true;
    } else {
      setSubjectError("");
    }

    // Submit the form if there are no errors
    // if (!hasError) {
    //   console.log("Submitting form", {
    //     Name,
    //     Email,
    //     Subject,
    //     Message,
    //   });
    //   try {
    //     const response = await axios.post(
    //       process.env.REACT_APP_API_KEY + "/api/addcontact",
    //       {
    //         data: {
    //           name: Name,
    //           email: Email,
    //           subject: Subject,
    //           message: Message,
    //         },
    //         message: "Details saved Successfully",
    //       }
    //     );
    //     console.log(response.data);
    //     toast.success("Form submitted successfully!", {
    //       bodyClassName: "my-toast-body",
    //       progressBarStyle: { backgroundColor: "#2F7175" }, // Change the bottom line color
    //       icon: <FaCheck color="#2F7175" />, // Change the icon color
    //     });
    //   } catch (error) {
    //     console.error(error);
    //     toast.error(
    //       "An error occurred while submitting the form. Please try again."
    //     );
    //   }
    // }
    if (!hasError) {
      console.log("Submitting form", {
        Name,
        Email,
        Subject,
        Message,
      });
      try {
        const response = await axios.post(
          // process.env.REACT_APP_API_KEY + "contact_api.php",
          {
            name: Name,
            email: Email,
            subject: Subject,
            message: Message,
            messageText: "Details saved Successfully",
          }
        );

        console.log(response.data);

        // Show success message to the user
        toast.success("Form submitted successfully!", {
          bodyClassName: "my-toast-body",
          progressBarStyle: { backgroundColor: "#2F7175" }, // Change the bottom line color
          icon: <FaCheck color="#2F7175" />, // Change the icon color
        });
      } catch (error) {
        console.error(error);
        // Show error message to the user
        toast.error(
          "An error occurred while submitting the form. Please try again."
        );
      }
    }
  };

  return (
    <>
      <ToastContainer className="my-toast-container" closeButton={false} />

      <div className="KFD-076">
        <h1 className="Vjhf0778">Get In Touch</h1>
      </div>
      <div className="JHf936">
        <div className="Teiuwr807">
          <form
            // onSubmit={handleSubmit}
            action="https://quarecres.mcxcontrol.com/api/contact_api.php"
            method="post"
            enctype="multipart/form-data"
          >
            <div className="fg86">
              <div className="SJDKGF">
                <label className="KUDHF8763">Name</label>
                <input
                  className="SJDKGF017"
                  type="text"
                  placeholder="Enter Name.."
                  name="name"
                  value={Name}
                  onChange={handleNameChange}
                  required
                />
                {NameError && <div className="COnFom">{NameError}</div>}
              </div>
              <div className="SJDKGF">
                <label className="KUDHF8763">Email address</label>
                <input
                  className="SJDKGF017"
                  type="email"
                  placeholder="Enter Email.."
                  id="email"
                  name="email"
                  value={Email}
                  onChange={handleEmailChange}
                  required // Add the required attribute here
                />
                {EmailError && <div className="COnFom">{EmailError}</div>}
              </div>
            </div>
            <div className="Ahgf018">
              <label className="KUDHF8763">Subject</label>
              <input
                className="KJFg027"
                type="text"
                placeholder="Subject.."
                id="Subject"
                name="subject"
                value={Subject}
                onChange={handleSubjectChange}
                required // Add the required attribute here
              />
              {SubjectError && <div className="COnFom">{SubjectError}</div>}
              <label className="KUDHF8763">Message</label>
              <textarea
                className="KJFg0270"
                type="text"
                placeholder="Message.. "
                name="message"
                value={Message}
                onChange={handleMessagechange}
                required // Add the required attribute here
              />
            </div>
            <button className="shiney-btn">Submit</button>
          </form>
        </div>
        <div className="Eq1321">
          <div className="Farqp01">
            <div className="JKGD083">
              <Icon className="dsuygfys" icon={mapMarkerRadius} />
              <p className="tr78re">.</p>
              <p className="tr78r3">.</p>
            </div>
            <div className="JFDg081">
              <h2 className="KFg9761ZZ">Location</h2>
              <p className="JKF9176ZCx">
                615, Lobby 2, Sakar-9, Nr. Old RBI Bank, Nr. City Gold, Ashram
                Road, Ahmedabad-380006
              </p>
            </div>
          </div>
          <div className="Farqp01">
            <div className="JKGD083">
              <Icon className="dsuygfys" icon={phoneCall} />
              <p className="tr78re">.</p>
              <p className="tr78r3">.</p>
            </div>
            <div className="JFDg081">
              <h2 className="KFg9761ZZ">Call Us On</h2>
              {/* <p className="JKF9176ZCx">
                +91 9512900526
                <br />
                +91 9512900526
              </p> */}
              <a className="JKF9176ZCx" href="tel:+91 9512900526">
                +91 9512900526
              </a>
              <br className="Vajk0891" />
              <a className="JKF9176ZCx" href="tel:+91 7948981300">
                +91 7948981300
              </a>
            </div>
          </div>
          <div className="Farqp01">
            <div className="JKGD083">
              <Icon className="dsuygfys" icon={mailRounded} />
              <p className="tr78re">.</p>
              <p className="tr78r3">.</p>
            </div>
            <div className="JFDg081">
              <h2 className="KFg9761ZZ">Mail Us On</h2>

              <a className="JKF9176ZCx" href="mailto:career@quarec.com">
                career@quarec.com
              </a>
              <br className="Vajk0891" />
              {/* <a className="JKF9176ZCx" href="mailto:info@quarec.com">
                info@quarec.com
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetInTouch;
